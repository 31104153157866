import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import C3Chart from 'react-c3js';
import 'c3/c3.css';

const colors = {
  pattern: ['#4ac18e', '#6d60b0', '#5468da']
};

let data = {
  type: 'donut',
  columns: [
    ['Available Proxies', 33],
    ['Used Proxies', 17.5],
], 

 onclick: function (d, i) { console.log("onclick", d, i); },
 onmouseover: function (d, i) { console.log("onmouseover", d, i); },
 onmouseout: function (d, i) { console.log("onmouseout", d, i); }
};


class DonutChart extends Component {


  constructor(props) {
    super(props);

    this.state = {
      data: {},
    };
    // data.columns = [];

    

    // this.data.columns[0] = ['Available proxies', this.props.availableProxies];
    // this.data.columns[1] = ['Used proxies', this.props.activeProxies - this.props.availableProxies];
    // this.data.columns[0] = ['Available proxies', 10];
    // this.data.columns[1] = ['Used proxies', 20];

  }

  

  componentDidMount (){
    console.log("**************************** ======> activeProxies", this.props.availableProxies);
    console.log("**************************** ======> availableProxies", this.props.activeProxies);
    if (this.props.availableProxies && this.props.activeProxies) { 
      data.columns[0][1] = this.props.availableProxies;
      data.columns[1][1] = this.props.activeProxies;
    }
    console.log("]]]]]]]]]]]]]]]]]]]]]]]] ======> data.columns", data);
    this.setState({});
  }

    render(){
      return (
        <AUX>
          <C3Chart key={data.columns[0][0]} data={data} color={colors} />
        </AUX>
      );
    }
  }

  export default DonutChart;