import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { Link } from 'react-router-dom';
import * as Api from '../../../Api/Axios';
import SweetAlert from 'react-bootstrap-sweetalert';

class ReleaseProxies extends Component {

    constructor(props) {
        super(props);
        this.state = {
            success_msg: false, confirm_msg: false, success_dlg: false,
            dynamic_title: '', dynamic_description: '', img_html: false,
            errorField: '',
        };
    }


    numberOfProxiesToRelease = 0;

    componentDidMount() {

    }


    onChange = (event) => {
        // let errorAttribute = event.target.id + "_err";
        this.numberOfProxiesToRelease = event.target.value;
        console.log("this.numberOfProxiesToRelease : ", this.numberOfProxiesToRelease);
        // this.setState({ [errorAttribute]: '' });
    }

    async releaseProxies() {


        let url = "adminReleaseProxies.php";

        console.log("url=", url)

        if (this.numberOfProxiesToRelease > 0) {
            let res = await Api.postRequest(url, {numberOfProxiesToRelease:this.numberOfProxiesToRelease});
            console.log("res=", res)
            switch (res.data.status) {
                case 201:
                    this.setState({ confirm_msg: false, success_dlg: true, dynamic_title: 'Released', dynamic_description: 'Proxies had been released!' })
                    this.setState({ success_msg: true });
                    break;
                case 400:
                    this.setState({ errorField: res.data.message });
                    this.setState({ img_html: true });
                    break;
                default:
                    break;
            }
        }
    }



    render() {
        return (
            <AUX>

                <div className="col-xl-3 col-lg-4 col-sm-6 m-b-30">
                    {this.state.img_html ?
                        <SweetAlert
                            title={<span><u>Error</u>!</span>}
                            warning
                            onConfirm={() => this.setState({ img_html: false })} >
                            {this.state.errorField}!
                        </SweetAlert> : null}
                </div>

                <div className="col-xl-3 col-lg-4 col-sm-6 m-b-30">
                    {this.state.success_msg ?
                        <SweetAlert
                            title="Proxies had been successfully released!"
                            success
                            confirmBtnBsStyle="success"
                            onConfirm={() => this.setState({ success_msg: false })}>
                        </SweetAlert> : null}
                </div>

                <div className="col-xl-3 col-lg-4 col-sm-6 m-b-30">
                    <p>A warning message, with a function attached to the "Confirm"-button...</p>
                    {this.state.confirm_msg ?
                        <SweetAlert
                            title="Are you sure you want to release proxies?"
                            warning
                            showCancel
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            onConfirm={() => this.lockUserAfterConfirm()}
                            onCancel={() => this.setState({ confirm_msg: false })} >
                            You won't be able to revert this!
                        </SweetAlert> : null}
                </div>

                <div className="accountbg"></div>
                <div className="wrapper-page">

                    <div className="card">
                        <div className="card-body">
                            <div className="p-3">

                                <form className="form-horizontal m-t-30" action="index.html">

                                    <div className="form-group">
                                        <select className="form-control" id="employeeId" placeholder="Enter Employee" onChange={(e) => this.onChange(e)}>
                                            <option value={0} >Please select number</option>
                                            <option value={100} >100</option>
                                            <option value={500} >500</option>
                                            <option value={1000} >1000</option>
                                            <option value={5000} >5000</option>
                                        </select>
                                    </div>

                                    <div className="form-group row m-t-20">
                                        <div className="col-12 text-right">
                                            <button className="btn btn-primary w-md waves-effect waves-light" type="button" onClick={() => this.releaseProxies()}>Release</button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>

            </AUX>
        );
    }
}


export default ReleaseProxies;