import React, { Component } from "react";
import ExcelLoader from '../Components/component_loadExcelToDb'

class ExcelToJson extends Component {
    state = {
    };

    excelType = 'firstNameList';
  


  render() {
    return (
      <div>
          <ExcelLoader excelFileType={this.excelType}>
          </ExcelLoader>
      </div>
    );
  }
}

export default ExcelToJson;