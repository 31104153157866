export async function copyTextToClipboard(textToCopy) {
    // Navigator Clipboard requires HTTPS and other secure context
    if (navigator.clipboard && window.isSecureContext) {
      // Navigator Clipboard writes text to the clipboard
      return navigator.clipboard.writeText(textToCopy);
    } else {
      // Create Text Area
      let textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      // Make Text Area not in Viewport, and set up invisible
      textArea.style.position = "absolute";
      textArea.style.opacity = 0;
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise((res, rej) => {
        // Perform a copy command and remove the text box
        document.execCommand('copy') ? res() : rej();
        textArea.remove();
      });
    }
  }