import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { Sparklines, SparklinesLine } from 'react-sparklines';
import { Scrollbars } from 'react-custom-scrollbars';
import { Link } from 'react-router-dom';
import { PieChart } from 'react-easy-chart';
import MixedChart from '../../Chartstypes/Apex/MixedChart';
import DonutChart from '../../Chartstypes/Apex/DonutChart';
import MultipleArea from '../../Chartstypes/Apex/MultipleArea';
import * as Api from '../../../Api/Axios';
import { debug } from '../Components/component_debug';
import DateInput from 'react-dates';

const FULL = 1;
const PARTIAL = 0;
const USERDATE = 1; 
const SYSDATE = 2; 

class dashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            simple: 80, simple68: 68, simple37: 37, simple72: 72,
            currentDate: '',
            userDetails: [],
            user: {},
            totals: {},
            accumTotals: {},
            proxyStat: {},
            currentMonth: 0,
            currentYear: 0,
            previousMonth: 0,
            prevMonthYear: 0,
            currentMonthNumOfDays: 0,
            prevMonthNumOfDays: 0,
        };

        this.statusToGet = "A";

    }

    userDetails = [];
    user = {
        id: -1,
        name: "",
        activityToGet: "Activate",
        statDate: '',
        yDataSet1: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        yDataSet2: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        yDataSet1Name: "Employee performance",
        yDataSet2Name: "Average performance",
        outpuxAxis: [],
        type: "datetime",
        refreshKey:''
    }

    totals = {
        id: 0,
        name: "",
        activityToGet: "Activate",
        statDate: '',
        yDataSet1: [],
        yDataSet2: [],
        yDataSet1Name: "Total for curr month",
        yDataSet2Name: "Total for prev month",
        outpuxAxis: [],
        type: "datetime"
    }

    accumTotals = {
        id: 0,
        name: "",
        activityToGet: "Activate",
        statDate: '',
        yDataSet1: [],
        yDataSet2: [],
        yDataSet1Name: "Accumulated Total for curr month",
        yDataSet2Name: "Accumulated Total for prev month",
        outpuxAxis: [],
        type: "datetime"
    }

    current = {
        month: 0,
        year: 0
    }

    previous = {
        month: 0,
        year: 0
    }

    proxyStat = {
        chosenDate: '',
        chosenDay: 0,
        availableProxies: 0,
        activeProxies: 0,
        totalActivated: 0,
        totalBlocked: 0,
        blockedProxies: [],
    }

    emptyEmployeeArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];



    componentDidMount() {
        this.initArrays();
        // this.setEmployeeStatistics(FULL);

        this.setCurrentMonthAndYear();
        this.setPrevtMonthAndYear();

        this.setTotalsStatistics();
        this.setProxyStatistics();

    }

    setProxyStatistics = () => {
        this.getAvailableProxiesCount();
        this.getActiveProxiesCount();
        this.setState({ proxyStat: this.proxyStat })
        console.log("]]]]]]]][[[[[[[[[[[[[[----------------------> this.proxyStat ", this.proxyStat);
    }

    setEmployeeStatistics = async (mode = FULL) => {
        await this.getEmployeeHourlyStatistics();
        await this.getAverageHourlyStatistics();

        if (mode === FULL) {
            // this.initEmployeeArray();
            this.getDate(this.user.statDate);
            this.getEmployeeList();
            this.setUserxAxisData();
            // this.statDate(PARTIAL);
        } else {
            // this.initEmployeeArray();
            this.setUserxAxisData();
            // this.statDate(PARTIAL);
        }
        this.forceUserChartsRender();
    }

    setTotalsStatistics = () => {
        this.setTotalsxAxisData();
        this.getDailyPerformancePerMonth(this.current.month, this.current.year, this.totals.yDataSet1, this.accumTotals.yDataSet1);
        this.getDailyPerformancePerMonth(this.previous.month, this.previous.year, this.totals.yDataSet2, this.accumTotals.yDataSet2);
        this.getActivityPerChosenDate(this.current.month, this.current.year, 'Activate');
        this.getActivityPerChosenDate(this.current.month, this.current.year, 'Block');
    }

    initArrays = () => {
        this.initMonthTotals(this.totals.yDataSet1);
        this.initMonthTotals(this.totals.yDataSet2);
        this.initMonthTotals(this.accumTotals.yDataSet1);
        this.initMonthTotals(this.accumTotals.yDataSet2);
    }

    initEmployeeArray = ()=>{
        this.user.yDataSet1 = [...this.emptyEmployeeArray];
        this.user.yDataSet2 = [...this.emptyEmployeeArray];
    }

    initMonthTotals = (arrayToInit) => {
        for (let i = 0; i < 31; i++) {
            arrayToInit.push(0);
        }

        debug("this.totals.yDataSet1", this.totals.yDataSet1);
        debug("this.totals.yDataSet2", this.totals.yDataSet2);
    }

    forceTotalChartsRender = (dataSet, dataSetType) => {
        const TOTALS = 1;
        const ACCUMTOT = 2;
        let randomNumber = "0" + Math.floor(Math.random() * 10 - 0.1);
        let date = this.current.year + "-" + this.current.month + "-" + randomNumber;
        dataSet.statDate = date;

        switch (dataSetType) {
            case TOTALS:
                this.setState({ totals: dataSet });
                break;
            case ACCUMTOT:
                this.setState({ accumTotals: dataSet });
                break;
        }
    }

    forceUserChartsRender = () => {
        let randomNumber = Math.floor(Math.random() * 1000);
        let key = this.current.year + "-" + this.current.month + "-" + randomNumber;
        this.user.refreshKey = key;
        this.setState({user:this.user});
    }

    setUserxAxisData = () => {
        let inputDate = this.user.statDate;
        console.log("*************************************** inputDate:", inputDate);
        let hour;
        let outpuxAxis = [];
        let elementOfxAxis;
        for (let i = 0; i < 24; i++) {
            hour = i < 10 ? "0" + i : i;
            elementOfxAxis = inputDate + " " + hour + ":00";
            outpuxAxis.push(elementOfxAxis);
        }
        this.user.outpuxAxis = [...outpuxAxis];
    }


    setTotalsxAxisData = () => {
        debug("&&&&&&&&&&&&&&&&&&& in setTotalsxAxisData");
        if (this.current.month === 0 || this.current.year === 0) return;
        let outpuxAxis = [];
        let day;
        let date;
        let month = this.current.month < 10 ? "0" + this.current.month : this.current.month;
        for (let i = 1; i <= 31; i++) {
            day = i < 10 ? "0" + i : i;
            date = this.current.year + "-" + month + "-" + day + " 00:00";
            // date = day + "/" + month + "/" + this.current.year + " 00:00";
            outpuxAxis.push(date);
        }
        this.totals.outpuxAxis = [...outpuxAxis];
        this.totals.id = 1;

        this.accumTotals.outpuxAxis = [...outpuxAxis];
        this.accumTotals.id = 1;

        this.setState({ totals: this.totals }, () => {
            debug("&&&&&&&&&&&&&&&&& this.state.totals", this.state.totals);
        });

        this.setState({ accumTotals: this.accumTotals })

    }

    getAvailableProxiesCount = async () => {
        let res = await Api.postRequest("dashboardGetAvailableProxiesCount.php", {});

        console.log("))))))))))))))))))))Result after API: getAvailableProxiesCount ", res);
        if (res && res.data && res.data.response && res.data.response && res.data.response.length > 0) {
            this.proxyStat.availableProxies = Number(res.data.response[0].availableProxiesCount);
            console.log("this.proxyStat: ", this.proxyStat);
        }

    }

    getActiveProxiesCount = async () => {
        let res = await Api.postRequest("dashboardGetActiveProxiesCount.php", {});

        console.log("))))))))))))))))))))Result after API: getActiveProxiesCount ", res);
        if (res && res.data && res.data.response && res.data.response && res.data.response.length > 0) {
            this.proxyStat.activeProxies = Number(res.data.response[0].activeProxiesCount);
            console.log("this.proxyStat: ", this.proxyStat);
        }

    }

    getBlockedProxiesPerProvider = async ()=>{
        let blockedProxy = {
            key :'',
            value: 0,
            color : ''
        }
        this.proxyStat.blockedProxies = [];

        let res = await Api.postRequest("dashboardGetBlockedProxiesPerProvider.php", {activityToGet: 'Block', statDate: this.user.statDate});

        console.log("))))))))))))))))))))Result after API: dashboardGetBlockedProxiesPerProvider ", res);
        if (res && res.data && res.data.response && res.data.response && res.data.response.data.length > 0) {
            res.data.response.data.map(proxyStat=>{
                blockedProxy.key = proxyStat.provider;
                blockedProxy.value = proxyStat.totalCount;
                blockedProxy.color = this.getRandomColor();
                this.proxyStat.blockedProxies.push({...blockedProxy});
            })
        }

        console.log("this.proxyStat.blockedProxies : ", this.proxyStat.blockedProxies);
        this.setState({proxyStat: this.proxyStat })
    }

    getRandomColor = () => {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
      }

    getEmployeeHourlyStatistics = async () => {
        console.log("::::::::: statDate, userId ::::::::", this.user.statDate, this.user.id);
        if (this.user.statDate === '' || this.user.id === -1) return;
        this.user.yDataSet1 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        let res = await Api.postRequest("dashboardGetEmployeeData.php", { activityToGet: this.user.activityToGet, statDate: this.user.statDate, employeeId: this.user.id });
        console.log("))))))))))))))))))))Result after API: dashboardGetEmployeeData ", res);
        let index;
        if (res && res.data && res.data.response && res.data.response.data && res.data.response.data.length > 0) {
            res.data.response.data.map(dataElement => {
                index = Number(dataElement.hourInDay);
                if (index !== NaN) {
                    console.log("KKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKK", Number(dataElement.countPerHour));
                    // debug("index",index );
                    this.user.yDataSet1[index] = Number(dataElement.countPerHour);
                }
            })
        }
        debug("this.user:>>>>>>>>>>>>>>> ", this.user);
        console.log( "this.user.yDataSet1",  this.user.yDataSet1);
        this.setState({ user: { ...this.user } });
    }

    getAverageHourlyStatistics = async () => {
        if (this.user.statDate === '') return;
        this.user.yDataSet2 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        let res = await Api.postRequest("dashboardGetAverageData.php", { activityToGet: this.user.activityToGet, statDate: this.user.statDate });

        debug("Result after API:", res);
        let index;
        if (res && res.data && res.data.response && res.data.response.data && res.data.response.data.length > 0) {
            res.data.response.data.map(dataElement => {
                index = Number(dataElement.hourInDay);
                if (index !== NaN) {
                    this.user.yDataSet2[index] = Number(dataElement.averagePerHour);
                }
            })
        }
        debug("this.user: ", this.user);
        this.setState({ user: { ...this.user } });
    }

    getDailyPerformancePerMonth = async (month, year, dataSetTotal, dataSetAccum) => {
        debug("month =======>", month);
        debug("year ==========> ", year);
        if (month === 0 || year === 0) return;
        let activityToGet = this.user.activityToGet;
        let statMonth = month;
        let statYear = year;
        let res = await Api.postRequest("dashboardGetDataPerDay.php", { activityToGet, statMonth, statYear });

        debug("Result after dashboardGetDataPerDay API:", res);
        let index;
        if (res && res.data && res.data.response && res.data.response.data && res.data.response.data.length > 0) {
            res.data.response.data.map(dataElement => {
                index = Number(dataElement.day);
                if (index !== NaN) {
                    dataSetTotal[index - 1] = Number(dataElement.totalCount);
                }
            })
        }

        for (let i = 0; i < 31; i++) {
            if (i > 0) {
                dataSetAccum[i] = dataSetAccum[i - 1] + dataSetTotal[i];
            } else {
                dataSetAccum[i] = dataSetTotal[i];
            }
        }

        debug("this.totals =============================> ", this.totals);
        this.setState({ totals: { ...this.totals } }, () => {
            this.forceTotalChartsRender(this.totals, 1);
        });
        this.setState({ accumTotals: { ...this.accumTotals } }, () => {
            this.forceTotalChartsRender(this.accumTotals, 2);
        });
    }


    getActivityPerChosenDate = async (month, year, activityToGet) => {
        if (month === 0 || year === 0) return;
        let statMonth = month;
        let statYear = year;
        let statDay = this.getChosenDay();
        let res = await Api.postRequest("dashboardGetDataPerDay.php", { activityToGet, statMonth, statYear });

        debug("Result after dashboardGetDataPerDay API:", res);
        let dayFromApi;
        if (res && res.data && res.data.response && res.data.response.data && res.data.response.data.length > 0) {
            res.data.response.data.map(dataElement => {
                dayFromApi = Number(dataElement.day);
                if (dayFromApi !== NaN) {
                    console.log("dayFromApi, statDay", dayFromApi, statDay);
                    if (statDay && (statDay) === dayFromApi) {
                        switch (activityToGet) {
                            case 'Activate':
                                this.proxyStat.totalActivated = Number(dataElement.totalCount);
                                break;
                            case 'Block':
                                this.proxyStat.totalBlocked = Number(dataElement.totalCount);
                                break;
                        }
                    }
                }
            })
        }
        console.log("?????????????????????????>>> this.proxyStat: ", this.proxyStat);
        this.proxyStat.chosenDay = statDay;
        this.setState({ proxyStat: this.proxyStat });
    }

    getDate = (dateValue) => {
        let current =  new Date(dateValue);
        console.log("-------------------------------------------------------------------> Date", current);
        let year = current.getFullYear();
        let month = (current.getMonth() + 1) < 10 ? "0" + (current.getMonth() + 1) : (current.getMonth() + 1);
        let day = current.getDate() < 10 ? "0" + current.getDate() : current.getDate();
        let strDate = year + "-" + month + "-" + day;
        console.log("-------------------------------------------------------------------> Date", strDate);
        debug("current.getDate():", current.getDate());
        debug("date: ", strDate);
        this.setState({ currentDate: strDate });
        this.user.statDate = strDate;
        this.proxyStat.chosenDate = strDate;
        this.setState({ user: this.user });
    }

    getChosenDay = () => {
        console.log("@@@@@@@@@@@@@@@@@@@@@@@@@---> this.proxyStat.chosenDate:", this.proxyStat.chosenDate);
        let date = new Date(this.proxyStat.chosenDate);
        console.log("@@@@@@@@@@@@@@@@@@@@@@@@@---> date:", date);
        let day = date.getDate();
        console.log("++++++++++++++++++++++++++++++++++>>>>>> the day of month is: ", day);
        return day;
    }

    onSetDate = (event) => {
        console.log("-------------------------------------------------------------------> Date", event.target.value);
        this.getDate(event.target.value);
        this.setEmployeeStatistics(PARTIAL);
        this.statDate(FULL);
    }

    setCurrentMonthAndYear = () => {
        let current = new Date();
        this.current.year = current.getFullYear();
        this.current.month = (current.getMonth() + 1);
        this.setState({ currentMonth: this.current.month });
        this.setState({ currentYear: this.current.year });
        let statDate = this.current.year + "-" + this.current.month + "-01";
        this.totals.statDate = statDate;
        this.accumTotals.statDate = statDate;

        //May be redundant
        let numberOfDaysInMonth = this.getCurMonthNumOfDays(this.current.month, this.current.year);
        this.setState({ currentMonthNumOfDays: numberOfDaysInMonth });
        debug("current month :", this.current.month);
        debug("current year :", this.current.year);
        debug("numberOfDaysInMonth: ", numberOfDaysInMonth);
    }

    setPrevtMonthAndYear = () => {
        let previous = new Date();
        previous.setDate(0);
        this.previous.year = previous.getFullYear();
        this.previous.month = (previous.getMonth() + 1);
        this.setState({ previousMonth: this.previous.month });
        this.setState({ prevMonthYear: this.previous.year });

        let numberOfDaysInMonth = this.getCurMonthNumOfDays(this.previous.month, this.previous.year);
        this.setState({ prevMonthNumOfDays: numberOfDaysInMonth });

        debug("prev month :", this.previous.month);
        debug("prev year :", this.previous.year);
        debug("prevMonthNumOfDays: ", numberOfDaysInMonth);
    }


    getCurMonthNumOfDays = (month, year) => {
        let numberOfDays = 0;
        let inputMonth = month * 1;
        switch (inputMonth) {
            case 1:
            case 3:
            case 5:
            case 7:
            case 8:
            case 10:
            case 12:
                numberOfDays = 31;
                break;
            case 4:
            case 6:
            case 9:
            case 11:
                numberOfDays = 30;
                break;
            case 2:
                numberOfDays = this.setFebDays(year);
                break;
            default:
                numberOfDays = 0;
        }

        return numberOfDays;
    }



    setFebDays = (year) => {
        if (year % 4 === 0) {
            return 29;
        }
        return 28;
    }

    getEmployeeList = async () => {
        let res = await Api.postRequest("getEmployeeList.php", { statusToGet: this.statusToGet });
        debug("result of getEmployeeList", res);
        if (res.data.status == 201) {
            this.userDetails = [...res.data.response.data];

            debug("userDetails: ", this.userDetails);
            if (this.userDetails.length > 0) {
                this.setState({ userDetails: this.userDetails });
            }
        }
    }

    onChange = (event) => {
        debug("ON CHANGE");
        this.user.id = event.target.value;
        let employeeIndex = this.userDetails.findIndex(element => { return element.employeeId === this.user.id });
        debug("employeeIndex:", employeeIndex);
        if (employeeIndex >= 0) {
            debug("employeeIndex", employeeIndex);
            this.user.name = this.userDetails[employeeIndex].employeeName;
            debug("Employee Name:", this.user.name);
        }
        debug("this.user: ", this.user);
        this.statDate(FULL);
    }

    statDate = async (mode) => {
        // mode === FULL ?this.getEmployeeHourlyStatistics():this.doNothing();
        // mode === FULL ?this.getAverageHourlyStatistics():this.doNothing();
        mode === FULL ?this.setEmployeeStatistics():this.doNothing();
        this.getActivityPerChosenDate(this.current.month, this.current.year, 'Activate');
        this.getActivityPerChosenDate(this.current.month, this.current.year, 'Block');
        this.getBlockedProxiesPerProvider();
    }

    doNothing(){

    }


    render() {
        debug("------------ in Render ...........");

        return (
            <AUX>
                <div className="wrapper" lang="es">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-sm-12">
                                <div className="page-title-box">
                                    <div className="btn-group pull-right">
                                        <ol className="breadcrumb hide-phone p-0 m-0">
                                            <li className="breadcrumb-item"><Link to="#">Babvo</Link></li>
                                            <li className="breadcrumb-item active">Dashboard 1</li>
                                        </ol>
                                    </div>
                                    <h4 className="page-title">Dashboard</h4>
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-4"></div>
                            <div className="col-4">
                                <div className="form-group row">
                                    <label for="example-date-input" className="col-sm-2 col-form-label">Date</label>
                                    <div className="col-sm-10">
                                        <input className="form-control" type="date" value={this.state.currentDate} id="example-date-input" lang="en" onChange={(e) => this.onSetDate(e)} />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label for="example-date-input" className="col-sm-2 col-form-label">Employee</label>
                                    <div className="col-sm-10">
                                        <select className="form-control" id="employeeId" placeholder="Enter Employee" onChange={(e) => this.onChange(e)}>
                                            <option value={0} selected>Please select</option>
                                            {
                                                this.state.userDetails ?
                                                    this.state.userDetails.map(user => {
                                                        return <option value={user.employeeId} >{user.employeeName + ' - ' + user.employeeId}</option>
                                                    }) : ''
                                            }

                                        </select>
                                    </div>
                                </div>

                            </div>
                            <div className="col-4"></div>
                        </div>

                        <div className="row">

                            <div className="col-xl-9">
                                <div className="row">
                                    <div className="col-md-11 pr-md-0">
                                        <div className="card m-b-20" style={{ Height: "486px" }}>
                                            <div className="card-body">
                                                <h4 className="mt-0 header-title">Employee performance vs average</h4>
                                                <MultipleArea key={this.state.user.refreshKey} date={this.state.user.statDate} data={this.state.user} />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="col-xl-3">
                                <div className="card m-b-20">
                                    <div className="card-body">
                                        <h4 className="mt-0 header-title">Proxy Statistics</h4>

                                        <ul className="list-inline widget-chart m-t-5 m-b-15 text-center">
                                            <li className="list-inline-item">
                                                <h5 className="mb-0">{this.state.proxyStat.activeProxies}</h5>
                                                <p className="text-muted font-14">Active proxies</p>
                                            </li>
                                            <li className="list-inline-item">
                                                <h5 className="mb-0">{this.state.proxyStat.availableProxies}</h5>
                                                <p className="text-muted font-14">Available proxies</p>
                                            </li>
                                        </ul>

                                        <div className="text-center">

                                            <PieChart
                                                key={this.state.proxyStat.activeProxies}
                                                label
                                                size={300}
                                                padding={5}
                                                innerHoleSize={200}
                                                data={[
                                                    { key: 'Available proxies', value: this.state.proxyStat.availableProxies, color: '#02a499' },
                                                    { key: 'Active proxies', value: this.state.proxyStat.activeProxies, color: '#3c4ccf' },

                                                ]}
                                            />

                                            <div className="clearfix"></div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="row">

                            <div className="col-xl-9">
                                <div className="row">
                                    <div className="col-md-11 pr-md-0">
                                        <div className="card m-b-20" style={{ Height: "486px" }}>
                                            <div className="card-body">
                                                <h4 className="mt-0 header-title">Monthly performance vs. last month</h4>
                                                <MultipleArea key={this.state.totals.statDate} date={this.state.totals.statDate} data={this.totals} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-3">
                                <div className="card m-b-20">
                                    <div className="card-body">
                                        <h4 className="mt-0 header-title">Proxy Statistics</h4>

                                        <ul className="list-inline widget-chart m-t-5 m-b-15 text-center">
                                            <li className="list-inline-item">
                                                <h5 className="mb-0">{this.state.proxyStat.totalActivated}</h5>
                                                <p className="text-muted font-14">Activated Proxies</p>
                                            </li>
                                            <li className="list-inline-item">
                                                <h5 className="mb-0">{this.state.proxyStat.totalBlocked}</h5>
                                                <p className="text-muted font-14">Blocked Proxies</p>
                                            </li>
                                        </ul>

                                        <div className="text-center">

                                            <PieChart
                                                key={this.state.proxyStat.chosenDay}
                                                label
                                                size={300}
                                                padding={5}
                                                innerHoleSize={200}
                                                data={[
                                                    { key: 'Available proxies', value: this.state.proxyStat.totalBlocked, color: '#02a499' },
                                                    { key: 'Active proxies', value: this.state.proxyStat.totalActivated, color: '#3c4ccf' },

                                                ]}
                                            />

                                            <div className="clearfix"></div>

                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>


                        <div className="row">

                            <div className="col-xl-9">
                                <div className="row">
                                    <div className="col-md-11 pr-md-0">
                                        <div className="card m-b-20" style={{ Height: "486px" }}>
                                            <div className="card-body">
                                                <h4 className="mt-0 header-title">Monthly performance vs. last month</h4>
                                                <MultipleArea key={this.state.accumTotals.statDate} date={this.state.accumTotals.statDate} data={this.accumTotals} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3">
                                <div className="card m-b-20">
                                    <div className="card-body">
                                        <h4 className="mt-0 header-title">Blocked proxies per provider</h4>

                                        {this.state.proxyStat.blockedProxies?
                                        <ul className="list-inline widget-chart m-t-5 m-b-15 text-center">
                                        
                                            {this.state.proxyStat.blockedProxies.map(blockedProxy =>
                                                <li className="list-inline-item">
                                                    <p className="mb-0">{blockedProxy.key} : {blockedProxy.value}</p>
                                                </li>
                                            )}
                                        </ul>:<></>}

                                        <div className="text-center">
                                        {this.state.proxyStat.blockedProxies?
                                            <PieChart
                                                key={this.state.proxyStat.chosenDay}
                                                label
                                                labels={true}
                                                size={300}
                                                padding={5}
                                                innerHoleSize={200}
                                                data={
                                                    this.state.proxyStat.blockedProxies

                                                }
                                            />:<></>}

                                            <div className="clearfix"></div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>




                        {/* <div className="row">
                            <div className="col-xl-6">
                                <div className="card m-b-20">
                                    <div className="card-body">
                                        <h4 className="mt-0 m-b-30 header-title">Latest Transactions</h4>

                                        <div className="table-responsive">
                                            <table className="table table-vertical mb-0">

                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <img src="assets/images/users/avatar-2.jpg" alt="user-image" className="thumb-sm rounded-circle mr-2" />
                                                            Herbert C. Patton
                                                        </td>
                                                        <td><i className="mdi mdi-checkbox-blank-circle text-success"></i> Confirm</td>
                                                        <td>
                                                            $14,584
                                                            <p className="m-0 text-muted font-14">Amount</p>
                                                        </td>
                                                        <td>
                                                            5/12/2016
                                                            <p className="m-0 text-muted font-14">Date</p>
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-secondary btn-sm waves-effect">Edit</button>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <img src="assets/images/users/avatar-3.jpg" alt="user-image" className="thumb-sm rounded-circle mr-2" />
                                                            Mathias N. Klausen
                                                        </td>
                                                        <td><i className="mdi mdi-checkbox-blank-circle text-warning"></i> Waiting payment</td>
                                                        <td>
                                                            $8,541
                                                            <p className="m-0 text-muted font-14">Amount</p>
                                                        </td>
                                                        <td>
                                                            10/11/2016
                                                            <p className="m-0 text-muted font-14">Date</p>
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-secondary btn-sm waves-effect">Edit</button>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <img src="assets/images/users/avatar-4.jpg" alt="user-image" className="thumb-sm rounded-circle mr-2" />
                                                            Nikolaj S. Henriksen
                                                        </td>
                                                        <td><i className="mdi mdi-checkbox-blank-circle text-success"></i> Confirm</td>
                                                        <td>
                                                            $954
                                                            <p className="m-0 text-muted font-14">Amount</p>
                                                        </td>
                                                        <td>
                                                            8/11/2016
                                                            <p className="m-0 text-muted font-14">Date</p>
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-secondary btn-sm waves-effect">Edit</button>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <img src="assets/images/users/avatar-5.jpg" alt="user-image" className="thumb-sm rounded-circle mr-2" />
                                                            Lasse C. Overgaard
                                                        </td>
                                                        <td><i className="mdi mdi-checkbox-blank-circle text-danger"></i> Payment expired</td>
                                                        <td>
                                                            $44,584
                                                            <p className="m-0 text-muted font-14">Amount</p>
                                                        </td>
                                                        <td>
                                                            7/11/2016
                                                            <p className="m-0 text-muted font-14">Date</p>
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-secondary btn-sm waves-effect">Edit</button>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <img src="assets/images/users/avatar-6.jpg" alt="user-image" className="thumb-sm rounded-circle mr-2" />
                                                            Kasper S. Jessen
                                                        </td>
                                                        <td><i className="mdi mdi-checkbox-blank-circle text-success"></i> Confirm</td>
                                                        <td>
                                                            $8,844
                                                            <p className="m-0 text-muted font-14">Amount</p>
                                                        </td>
                                                        <td>
                                                            1/11/2016
                                                            <p className="m-0 text-muted font-14">Date</p>
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-secondary btn-sm waves-effect">Edit</button>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6">
                                <div className="card m-b-20">
                                    <div className="card-body">
                                        <h4 className="mt-0 m-b-30 header-title">Latest Orders</h4>

                                        <div className="table-responsive">
                                            <table className="table table-vertical mb-0">

                                                <tbody>
                                                    <tr>
                                                        <td>#12354781</td>
                                                        <td>
                                                            <img src="assets/images/products/1.jpg" alt="user-image" style={{ height: "46px" }} className="rounded mr-2" />
                                                            Riverston Glass Chair
                                                        </td>
                                                        <td><span className="badge badge-pill badge-success">Delivered</span></td>
                                                        <td>
                                                            $185
                                                        </td>
                                                        <td>
                                                            5/12/2016
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-secondary btn-sm waves-effect">Edit</button>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>#52140300</td>
                                                        <td>
                                                            <img src="assets/images/products/2.jpg" alt="user-image" style={{ height: "46px" }} className="rounded mr-2" />
                                                            Shine Company Catalina
                                                        </td>
                                                        <td><span className="badge badge-pill badge-success">Delivered</span></td>
                                                        <td>
                                                            $1,024
                                                        </td>
                                                        <td>
                                                            5/12/2016
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-secondary btn-sm waves-effect">Edit</button>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>#96254137</td>
                                                        <td>
                                                            <img src="assets/images/products/3.jpg" alt="user-image" style={{ height: "46px" }} className="rounded mr-2" />
                                                            Trex Outdoor Furniture Cape
                                                        </td>
                                                        <td><span className="badge badge-pill badge-danger">Cancel</span></td>
                                                        <td>
                                                            $657
                                                        </td>
                                                        <td>
                                                            5/12/2016
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-secondary btn-sm waves-effect">Edit</button>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>#12365474</td>
                                                        <td>
                                                            <img src="assets/images/products/4.jpg" alt="user-image" style={{ height: "46px" }} className="rounded mr-2" />
                                                            Oasis Bathroom Teak Corner
                                                        </td>
                                                        <td><span className="badge badge-pill badge-warning">Shipped</span></td>
                                                        <td>
                                                            $8451
                                                        </td>
                                                        <td>
                                                            5/12/2016
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-secondary btn-sm waves-effect">Edit</button>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>#85214796</td>
                                                        <td>
                                                            <img src="assets/images/products/5.jpg" alt="user-image" style={{ height: "46px" }} className="rounded mr-2" />
                                                            BeoPlay Speaker
                                                        </td>
                                                        <td><span className="badge badge-pill badge-success">Delivered</span></td>
                                                        <td>
                                                            $584
                                                        </td>
                                                        <td>
                                                            5/12/2016
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-secondary btn-sm waves-effect">Edit</button>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </AUX>
        );
    }
}

export default dashboard;