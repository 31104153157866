import React , { Component } from 'react';
import Dashboard from '../MainContent/Dashboard/Dashboard';
import Form_user from '../MainContent/Forms/Form_user';
import Pages_loadProxies from '../MainContent/Pages/Pages_loadProxies';
import Pages_loadFirstNames from '../MainContent/Pages/Pages_loadFirstNames';
import Pages_loadLastNames from '../MainContent/Pages/Pages_loadLastNames';
import Pages_loadHobbies from '../MainContent/Pages/Pages_loadHobbies'
import Pages_loadUserTypes from '../MainContent/Pages/Pages_loadUserTypes'
import Pages_lock_screen from '../MainContent/Pages/Pages_lock_screen';
import Pages_login_2 from '../MainContent/Pages/Pages_login_2';
import Pages_recoverpw from '../MainContent/Pages/Pages_recoverpw';
import Pages_register from '../MainContent/Pages/Pages_register';
import Pages_LockUser from '../MainContent/Pages/Pages_LockUser';
import Pages_ResumeLockedUser from '../MainContent/Pages/Pages_ResumeLockedUser';
import User_Form from '../MainContent/Forms/Form_user';
import Pages_ReleaseProxies from '../MainContent/Pages/Pages_ReleaseProxies';
import DailyReport from '../MainContent/Pages/Pages_dailyReport';

import { Route,Switch } from 'react-router-dom';
import ResetPassword from '../MainContent/Pages/Pages_resetPassword';
import Home from '../MainContent/Pages/Home';
import Pages_changePassword from '../MainContent/Pages/Pages_changePassword'
import ProtectedRouteAdmin from '../../Authentication/ProtectedRouteAdmin';
import ProtectedRouteMaster from '../../Authentication/ProtectedRouteMaster';
import ProtectedRouteUser from '../../Authentication/ProtectedRouteUser';

class mainbuilder extends Component{
    render(){
        return(
                <Switch> 

                   <ProtectedRouteUser path="/form_user" component={Form_user} />
                   <Route path="/Home" component={Home} />
                   
                   <ProtectedRouteAdmin path="/Pages_loadProxies" component={Pages_loadProxies} />
                   <ProtectedRouteAdmin path="/Pages_loadFirstNames" component={Pages_loadFirstNames} />
                   <ProtectedRouteAdmin path="/Pages_loadLastNames" component={Pages_loadLastNames} />
                   <ProtectedRouteAdmin path="/Pages_loadHobbies" component={Pages_loadHobbies} />
                   <ProtectedRouteAdmin path="/Pages_loadUserTypes" component={Pages_loadUserTypes} />
                   <ProtectedRouteUser path="/User_Form" component={User_Form} />
                   <ProtectedRouteAdmin path="/Pages_ReleaseProxies" component={Pages_ReleaseProxies}/>
                   <ProtectedRouteAdmin path="/Pages_dailyReport" component={DailyReport}/>
                   <ProtectedRouteAdmin path="/Pages_resetPassword" component={ResetPassword}/>

                   <Route path="/pages_lock_screen" component={Pages_lock_screen} />
                   <Route path="/pages_login_2" component={Pages_login_2} />
                   <Route path="/pages_recoverpw" component={Pages_recoverpw} />
                   <ProtectedRouteAdmin path="/pages_register" component={Pages_register} />
                   <ProtectedRouteAdmin path="/pages_LockUser" component={Pages_LockUser} />
                   <ProtectedRouteAdmin path="/Pages_ResumeLockedUser" component={Pages_ResumeLockedUser} />
                   <ProtectedRouteMaster path="/Dashboard" component={Dashboard} />
                   <ProtectedRouteUser path="/Pages_changePassword" component={Pages_changePassword} />
                   <Route path="/" component={Home} />
                  
                    
                    
                </Switch>
        );
    }
}

export default mainbuilder;