import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actionTypes from '../../../store/action';
import * as Api from '../../../Api/Axios';
import SweetAlert from 'react-bootstrap-sweetalert';

class Pages_register extends Component {

    constructor(props) {
        super(props);
        this.state = {
            basic: false, with_title: false, success_msg: false, confirm_msg: false, success_dlg: false, error_dlg: false,
            dynamic_title: '', dynamic_description: '', confirm_both: false, img_header: false, img_html: false, custom_div: false,
            emailtxt: '', emailerr: '', step1: false, step1_txt: '', step2: false, step2_txt: '', step3: false, step3_txt: '', step_queue: false,
            final_queue: false, current_ip: '219.91.239.22', close_timer: false, timeralert: null,
            errorField: '',
            employeeId_err: '',
            useremail_err: '',
            username_err: '',
            userpassword_err: '',
            confirmpassword_err: '',
            userRole_err: '',
            firstName_err: '',
            lasttName_err: '',
            phone_err: '',
            adress_err: '',
            startDate_err: '',
            project_err: '',
            gender_err: ''
        };
    }

    userDetails = {
        employeeId: -1,
        useremail: '',
        username: '',
        userpassword: '',
        confirmpassword: '',
        userRole: 1,
        firstName: '',
        lasttName: '',
        phone: '',
        adress: '',
        startDate: '',
        project: 1,
        gender: 1
    }

    inputValidations = {
        employeeIdValid: false,
        useremailValid: false,
        usernameValid: false,
        userpasswordValid: false,
        confirmpasswordValid: false,
        userRoleValid: true,
        firstNameValid: false,
        lasttNameValid: false,
        phoneValid: false,
        adressValid: false,
        startDateValid: false,
        projectValid: true,
        genderValid: true,

    }

    isInputValid = this.inputValidations.employeeIdValid && this.inputValidations.useremailValid && this.inputValidations.usernameValid &&
        this.inputValidations.userpasswordValid && this.inputValidations.confirmpasswordValid && this.inputValidations.userRoleValid &&
        this.inputValidations.firstNameValid && this.inputValidations.lasttNameValid && this.inputValidations.phoneValid &&
        this.inputValidations.adressValid && this.inputValidations.startDateValid && this.inputValidations.projectValid &&
        this.inputValidations.genderValid;

    componentDidMount() {
        if (this.props.loginpage === false) {
            this.props.UpdateLogin();
        }
        window.onpopstate = (e) => {
            this.props.UpdateLoginAgain();
        }
    }

    onChange = (event) => {
        let errorAttribute = event.target.id + "_err";
        this.userDetails[event.target.id] = event.target.value;
        console.log("userDetails : ", this.userDetails);
        this.setState({ [errorAttribute]: '' });
    }

    handleChangeEmail(e) {
        this.setState({ useremail: e.target.value });
        var EmailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (e.target.value == '')
            this.setState({ useremail_err: 'Required Field' });
        else if (EmailReg.test(e.target.value))
            this.setState({ useremail_err: '' });
        else
            this.setState({ useremail_err: 'Enter Valid Email' });

        console.log("Email: ", this.state.useremail, this.state.useremail_err);
    }

    validateNumeric = (e) => {
        const re = /[0-9]+/g;
        if (!re.test(e.key)) {
            e.preventDefault();
        }
    }

    onSubmit = () => {
        this.validateInput();
        this.createUser();
    }

    validateInput = () => {
        // phone :'',
        // startDate : '',

        this.validateEmployeeId(this.userDetails.employeeId);
        this.validateFirstName(this.userDetails.firstName);
        this.validateLastName(this.userDetails.lasttName);
        this.validateAddress(this.userDetails.adress);
        this.validateUserName(this.userDetails.username);
        this.validatePassword(this.userDetails.userpassword);
        this.validateConfirmPassword();
        this.validateEmail(this.userDetails.useremail);
        this.validateStartDate(this.userDetails.startDate);
        this.validatePhoneNumber(this.userDetails.phone);
        this.setValidationInd();
    }

    validatePhoneNumber = (phone) => {
        const pattern = /^[0]{1}[0-9]{2}-[0-9]{4}-[0-9]{3}|[0]{1}[0-9]{9}$/;
        this.inputValidations.phoneValid = false;
        if (phone == '' || phone.length == 0) {
            this.setState({ phone_err: "Phone number is required" });
        } else if (!pattern.test(phone)) {
            this.setState({ phone_err: "incorrect phone number format" });
        } else {
            this.inputValidations.phoneValid = true;
        }
    }

    validateStartDate = (date = "") => {
        const dateRegex = /^(\d\d\d\d-(0?[1-9]|1[0-2])-(0?[1-9]|[12][0-9]|3[01]))|(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[0-2])\/\d\d\d\d$/;
        let parsedDate = new Date(date);
        this.inputValidations.startDateValid = false;

        if (date == '' || date.length == 0) {
            this.setState({ startDate_err: "Start working date is required" });
        } else if (!dateRegex.test(date)) {
            this.setState({ startDate_err: "Start working date has a wrong format" });
        } else if (parsedDate.toString() == "Invalid Date") {
            this.setState({ startDate_err: "Incorrect date" });
        } else {
            this.inputValidations.startDateValid = true;
        }
    }

    validateEmployeeId(employeeId) {

        this.inputValidations.employeeIdValid = false;
        console.log("employeeId:", employeeId);
        if (employeeId == -1) {
            console.log("setting the error to : Employee Id is required");
            this.setState({ employeeId_err: "Employee Id is required" });
        } else if (Number(employeeId) == NaN) {
            console.log("setting the error to : Please put a valid employee Id");
            this.setState({ employeeId_err: "Please put a valid employee Id" });
            this.inputValidations.employeeIdValid = true;
        } else if (employeeId < 1000 || employeeId > 999999) {
            console.log("setting the error to : employee Id is not valid");
            this.setState({ employeeId_err: "employee Id is not valid" });
        } else {
            this.inputValidations.employeeIdValid = true;
        }

    }

    validateFirstName(firstName) {
        this.inputValidations.firstNameValid = false;
        if (!firstName || firstName.length == 0) {
            this.setState({ firstName_err: "First name is a required field" });
        } else if (firstName.length < 2) {
            this.setState({ firstName_err: "First name is too short" });
        } else {
            this.inputValidations.firstNameValid = true;
        }
    }

    validateLastName(lastName) {

        this.inputValidations.lasttNameValid = false;
        if (!lastName || lastName.length == 0) {
            this.setState({ lasttName_err: "Last name is a required field" });
        } else if (lastName.length < 2) {
            this.setState({ lasttName_err: "Last name is too short" });
        } else {
            this.inputValidations.lasttNameValid = true;
        }
    }

    validateAddress(address) {

        this.inputValidations.adressValid = false;
        if (!address || address.length == 0) {
            this.setState({ adress_err: "Address is a required field" });
        } else if (address.length < 2) {
            this.setState({ adress_err: "Address is too short" });
        } else {
            this.inputValidations.adressValid = true;
        }
    }

    validateUserName(userName) {

        this.inputValidations.usernameValid = false;
        if (!userName || userName.length == 0) {
            this.setState({ username_err: "User name is a required field" });
        } else {
            this.inputValidations.usernameValid = true;
        }
    }

    validatePassword(userpassword) {

        this.inputValidations.userpasswordValid = false;
        if (!userpassword || userpassword.length == 0) {
            this.setState({ userpassword_err: "User name is a required field" });
        } else if (userpassword.length < 6) {
            this.setState({ userpassword_err: "Password too short" });
        } else {
            this.inputValidations.userpasswordValid = true;
        }
    }

    validateConfirmPassword() {

        this.inputValidations.confirmpasswordValid = false;
        if (this.userDetails.userpassword != this.userDetails.confirmpassword) {
            this.setState({ confirmpassword_err: "Passwords don't match" });
        } else {
            this.inputValidations.confirmpasswordValid = true;
        }
    }

    validateEmail(email) {

        this.inputValidations.useremailValid = false;
        var EmailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (email == '') {
            this.setState({ useremail_err: 'Required Field' });
        }
        else if (EmailReg.test(email)) {
            this.setState({ useremail_err: '' });
            this.inputValidations.useremailValid = true;
        }
        else
            this.setState({ useremail_err: 'Enter Valid Email' });
    }

    setValidationInd = () => {
        this.isInputValid = this.inputValidations.employeeIdValid && this.inputValidations.useremailValid && this.inputValidations.usernameValid &&
            this.inputValidations.userpasswordValid && this.inputValidations.confirmpasswordValid && this.inputValidations.userRoleValid &&
            this.inputValidations.firstNameValid && this.inputValidations.lasttNameValid && this.inputValidations.phoneValid &&
            this.inputValidations.adressValid && this.inputValidations.startDateValid && this.inputValidations.projectValid &&
            this.inputValidations.genderValid;
    }


    async createUser() {
        this.validateInput();
        console.log("isInputValid:", this.isInputValid);
        console.log("inputValidations", this.inputValidations);

        let url = "adminRegister.php";

        console.log("url=", url)

        if (this.isInputValid) {
            let res = await Api.postRequest("adminRegister.php", this.userDetails);
            console.log("res=", res)
            switch (res.data.status) {
                case 201:
                    this.setState({ success_msg: true });
                    break;
                case 400:
                    this.setState({ errorField: res.data.message });
                    this.setState({ img_html: true });
                    break;
                default:
                    break;
            }
        }
    }



    render() {
        return (
            <AUX>

                <div className="col-xl-3 col-lg-4 col-sm-6 m-b-30">
                    {this.state.img_html ?
                        <SweetAlert
                            title={<span><u>Error</u>!</span>}
                            warning
                            onConfirm={() => this.setState({ img_html: false })} >
                            {this.state.errorField}!
                        </SweetAlert> : null}
                </div>

                <div className="col-xl-3 col-lg-4 col-sm-6 m-b-30">
                    {this.state.success_msg ?
                        <SweetAlert
                            title="Great, The user is created successfully in the system!"
                            success
                            confirmBtnBsStyle="success"
                            onConfirm={() => this.setState({ success_msg: false })}>
                        </SweetAlert> : null}
                </div>

                <div className="accountbg"></div>
                <div className="wrapper-page">

                    <div className="card">
                        <div className="card-body">

                            <h3 className="text-center m-0">
                                <Link to="/" onClick={() => this.props.UpdateLoginAgain()} className="logo logo-admin"><img src="assets/images/logo.png" height="30" alt="logo" /></Link>
                            </h3>

                            <div className="p-3">
                                <h4 className="font-18 m-b-5 text-center">Create a new user</h4>

                                <form className="form-horizontal m-t-30" action="index.html">

                                    <div className="form-group">
                                        <label for="employeeId">Employee Id</label>
                                        <input type="number" className="form-control" id="employeeId" placeholder="Enter employee id" onChange={(e) => this.onChange(e)} onKeyPress={(e) => this.validateNumeric(e)} />
                                        <span id="err" className="error-span">{this.state.employeeId_err}</span>
                                    </div>
                                    <div className="form-group">
                                        <label for="useremail">Email</label>
                                        <input type="email" className="form-control" id="useremail" placeholder="Enter email" onChange={(e) => this.onChange(e)} />
                                        <span className="error-span" id="err">{this.state.useremail_err}</span>
                                    </div>

                                    <div className="form-group">
                                        <label for="username">Username</label>
                                        <input type="username" className="form-control" id="username" placeholder="Enter username" onChange={(e) => this.onChange(e)} />
                                        <span className="error-span" id="err">{this.state.username_err}</span>
                                    </div>

                                    <div className="form-group">
                                        <label for="userpassword">Password</label>
                                        <input type="password" className="form-control" id="userpassword" placeholder="Enter password" onChange={(e) => this.onChange(e)} />
                                        <span className="error-span" id="err">{this.state.userpassword_err}</span>
                                    </div>

                                    <div className="form-group">
                                        <label for="userpassword">Confirm Password</label>
                                        <input type="password" className="form-control" id="confirmpassword" placeholder="Confirm password" onChange={(e) => this.onChange(e)} />
                                        <span className="error-span" id="err">{this.state.confirmpassword_err}</span>
                                    </div>

                                    <div className="form-group">
                                        <label for="userpassword">User Role</label>
                                        <select className="form-control form-select" id="userRole" placeholder="Confirm password" onChange={(e) => this.onChange(e)}>
                                            <option value="1" selected>Regular</option>
                                            <option value="2">Master</option>
                                            <option value="3">Admin</option>
                                        </select>
                                        <span className="error-span" id="err">{this.state.userRole_err}</span>
                                    </div>

                                    <div className="form-group">
                                        <label for="username">First Name</label>
                                        <input type="text" className="form-control" id="firstName" placeholder="Enter first name" onChange={(e) => this.onChange(e)} />
                                        <span className="error-span" id="err">{this.state.firstName_err}</span>
                                    </div>

                                    <div className="form-group">
                                        <label for="username">Last Name</label>
                                        <input type="text" className="form-control" id="lasttName" placeholder="Enter last name" onChange={(e) => this.onChange(e)} />
                                        <span className="error-span" id="err">{this.state.lasttName_err}</span>
                                    </div>

                                    <div className="form-group">
                                        <label for="username">User Phone</label>
                                        <input type="text" className="form-control" id="phone" placeholder="Enter user phone" onChange={(e) => this.onChange(e)} />
                                        <span className="error-span" id="err">{this.state.phone_err}</span>
                                    </div>

                                    <div className="form-group">
                                        <label for="username">Address</label>
                                        <input type="text" className="form-control" id="adress" placeholder="Enter user address" onChange={(e) => this.onChange(e)} />
                                        <span className="error-span" id="err">{this.state.adress_err}</span>
                                    </div>

                                    <div className="form-group">
                                        <label for="username">Work start date</label>
                                        <input type="date" className="form-control" id="startDate" placeholder="Enter start date" onChange={(e) => this.onChange(e)} />
                                        <span className="error-span" id="err">{this.state.startDate_err}</span>
                                    </div>

                                    <div className="form-group">
                                        <label for="username">Project</label>
                                        <select className="form-control" id="project" placeholder="Enter user project" onChange={(e) => this.onChange(e)}>
                                            <option value="1" selected>Voyager</option>
                                        </select>
                                        <span className="error-span" id="err">{this.state.project_err}</span>
                                    </div>

                                    <div className="form-group">
                                        <label for="username">User Gender</label>
                                        <select className="form-control" id="gender" placeholder="Enter user gender" onChange={(e) => this.onChange(e)}>
                                            <option value="1" selected>Male</option>
                                            <option value="2">Female</option>
                                        </select>
                                        <span className="error-span" id="err">{this.state.gender_err}</span>
                                    </div>

                                    <div className="form-group row m-t-20">
                                        <div className="col-12 text-right">
                                            <button className="btn btn-primary w-md waves-effect waves-light" type="button" onClick={() => this.createUser()}>Register</button>
                                        </div>
                                    </div>

                                </form>
                            </div>

                        </div>
                    </div>

                    <div className="m-t-40 text-center">
                        <p className="text-white">Already have an account ? <Link to="pages_login" className="font-500 font-14 text-white font-secondary"> Login </Link> </p>
                        {/* <p className="text-white">©  {new Date().getFullYear()-1} -  {new Date().getFullYear()} Babvo. Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesbrand</p> */}
                    </div>

                </div>

            </AUX>
        );
    }
}

export default Pages_register;