import React , {Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { Link } from 'react-router-dom';
import {debug} from '../../../Utilities/Logger';
import * as Api from '../../../Api/Axios';
import SweetAlert  from 'react-bootstrap-sweetalert';

class Pages_login_2 extends Component{
    constructor(props){
        super(props);
    }

    componentDidMount() {
        if(this.props.loginpage === false)
        {
            this.props.UpdateLogin();
        }
        window.onpopstate  = (e) => {
            this.props.UpdateLoginAgain();
        }
    }

    userDetails = {
        username: '',
        password: '',
        newPassword:'',
        confirmPassword:''
    }

    state = {   basic:false, with_title:false, success_msg:false, confirm_msg:false, success_dlg:false, error_dlg:false,  
        dynamic_title:'', dynamic_description: '', confirm_both:false, img_header:false, img_html:false, custom_div:false, 
        emailtxt:'',emailerr:'', step1:false, step1_txt:'', step2:false, step2_txt:'', step3:false, step3_txt:'',step_queue:false,
        final_queue:false, current_ip:'219.91.239.22', close_timer:false, timeralert:null,
        errorField:'' ,
    };

    setDetailsFromInput  = (e) => {
        this.userDetails[e.target.id] = e.target.value;
        debug("userDetails = " , this.userDetails);
        console.log("Debugging");
    }

    submitForm = async (e) => {
        debug("username:", this.userDetails.username);
        debug("password:", this.userDetails.password);

        let url ='';
        url = '/changePassword.php';
        let result =  await Api.postRequest(url, this.userDetails);
        let errorDesc;
        console.log("result: ", result);
        if (result && result.data) {
            let retStatus = result.data.status;
            console.log("retStatus = ", retStatus);
            switch (retStatus){
                case 201:
                    console.log("Setting success_msg to true");
                    this.setState({ success_msg: true })
                    this.props.history.push("/Pages_login_2");
                    break;
                case 404:
                    errorDesc = "Incorrect user name or password!";
                    this.setState({errorField : errorDesc})
                    this.setState({ img_html: true })
                    break;
                case 400:
                    errorDesc = "Please provide a valid user name and password!";
                    this.setState({errorField : errorDesc})
                    this.setState({ img_html: true })
                    break;
                case 403:
                    errorDesc = "Login information is incorrect!";
                    this.setState({errorField : errorDesc})
                    this.setState({ img_html: true })
                    break;
                default:
                    errorDesc = "Unknown error!";
                    this.setState({errorField : errorDesc})
                    this.setState({ img_html: true })
                    break; 
            }
        }
    }

 
render(){
    return(
           <AUX>


                <div className="col-xl-3 col-lg-4 col-sm-6 m-b-30">
                    {this.state.img_html ? 
                    <SweetAlert  
                            title={<span><u>Error</u>!</span>}
                            warning
                            onConfirm={() => this.setState({ img_html: false })} >
                            {this.state.errorField}!
                    </SweetAlert>  : null }
                </div>

                <div className="col-xl-3 col-lg-4 col-sm-6 m-b-30">
                    {this.state.success_msg ? 
                    <SweetAlert  
                            title="Great, You changed your password successfully!" 
                            success
                            confirmBtnBsStyle="success"
                            onConfirm={() => this.setState({ success_msg: false })}>
                    </SweetAlert>  : null }
                </div>


      <div className="accountbg" style={{ background : "url('assets/images/gallery/micah-williams-lmFJOx7hPc4-unsplash.jpg')",backgroundSize : "cover" }}></div>
         <div className="wrapper-page account-page-full">
            <div className="card">
                <div className="card-body">

                    <h3 className="text-center m-0">
                        <Link to="/" onClick={()=> this.props.UpdateLoginAgain()} className="logo logo-admin"><img src="assets/images/logo.png" height="30" alt="logo" /></Link>
                    </h3>

                    <div className="p-3">
                        <h4 className="font-18 m-b-5 text-center">Welcome Back !</h4>
                        <p className="text-muted text-center">Sign in to continue to Babvo.</p>

                        <form className="form-horizontal m-t-30" action="/">

                            <div className="form-group">
                                <label for="userName">Username</label>
                                <input type="text" className="form-control" id="username" placeholder="Enter userName" 
                                 onChange={e => this.setDetailsFromInput(e)}/>
                            </div>

                            <div className="form-group">
                                <label for="userPassword">Password</label>
                                <input type="password" className="form-control" id="password" placeholder="Enter password" 
                                 onChange={e => this.setDetailsFromInput(e)}/>
                            </div>

                            <div className="form-group">
                                <label for="userPassword">New Password</label>
                                <input type="password" className="form-control" id="newPassword" placeholder="Enter new password" 
                                 onChange={e => this.setDetailsFromInput(e)}/>
                            </div>

                            <div className="form-group">
                                <label for="userPassword">Confirm New Password</label>
                                <input type="password" className="form-control" id="confirmPassword" placeholder="Confirm new password" 
                                 onChange={e => this.setDetailsFromInput(e)}/>
                            </div>

                            <div className="form-group row m-t-20">

                                <div className="col-sm-6 text-right">
                                    <button className="btn btn-primary w-md waves-effect waves-light" type="button"
                                     onClick={e => this.submitForm(e)}>Change Password</button>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>

            <div className="m-t-40 text-center">
                <p className="">© Babvo {new Date().getFullYear()}. </p>
            </div>
        </div>
           </AUX>
        );
    }
}

export default Pages_login_2;
