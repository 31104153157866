import React, { Component } from "react";
import UserStatusUpdater from '../Components/component_userStatusUpdater';

class Pages_LockUser extends Component {
  state = {
  };

  userStatus = 'A';



  render() {
    return (
      <div>
        <UserStatusUpdater userStatus={this.userStatus}>
        </UserStatusUpdater>
      </div>
    );
  }
}

export default Pages_LockUser;