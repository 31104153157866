import React , {Component } from 'react';
import AUX from '../../../hoc/Aux_';
import ReactApexChart from 'react-apexcharts';
import { timers } from 'jquery';
import {debug} from '../../MainContent/Components/component_debug';

class MultipleArea extends Component{

    constructor(props) {
        super(props);

        this.state = {
          id:0,
          refreshKey : '',
          options: {
            dataLabels: {
              enabled: false
            },
            toolbar: {
              show: false,
            },
            stroke: {
              show: true,
              curve: 'smooth',
              lineCap: 'butt',
              colors: undefined,
              width: 0,
              dashArray: 0,      
          },
          xaxis: {
            type: '',
            categories: [],
          },
          tooltip: {
            x: {
              format: 'dd/MM/yy HH:mm'
            },
          }
        },
          series: [{
            name: 'series1',
            data: [10, 40, 25, 51, 42, 80, 15]
          }, {
            name: 'Average',
            data: [15, 35, 45, 92, 34, 62, 14]
          },],
        }
      }

      lastUpdateDate = '1970-01-01';
      id = -1;

      componentDidMount(){
        debug("********************************** this.props.data.yDataSet1: ", this.props.data.yDataSet1);
        debug("********************************** data in props: ", this.props.data);
        this.setDataForChart();
        debug("After set state : ", this.state);
        this.refreshChildKey(this.props.data.statDate);
        //this.lastUpdateDate = this.props.data.statDate;
      }

      setDataForChart = () =>{
        debug("#############################  setDataForChart  ##############################################");
        debug("this.props.data.yDataSet1 ------------->", this.props.data.yDataSet1);
        debug("this.props.data.yDataSet2 ------------->", this.props.data.yDataSet2);
        let series = [...this.state.series];
        series[0].name = this.props.data.yDataSet1Name;
        series[1].name = this.props.data.yDataSet2Name;
        series[0].data = this.props.data.yDataSet1;
        series[1].data = this.props.data.yDataSet2;
        this.setState({series});

        let xaxis = {
          type: this.props.data.type,
          categories: this.setxAxisData(),
        }

        debug("xaxis -------------------------------------)) ", xaxis);

        this.setState({
          options: {
            ...this.state.options,
            xaxis: xaxis
          }
        } , ()=>{
          debug("Options -------------------------------------)) ", this.state.options);
        });

        debug("########### this.state", this.state);
      }

      setxAxisData = () =>{
        this.refreshChildKey(this.props.data.statDate);
        if (this.props.data.outpuxAxis) return [...this.props.data.outpuxAxis];
      }

      refreshChildKey = (inputDate)=>{
        let randomNumber = Math.floor(Math.random()*1000);
        let refreshKey = this.id + "-" + randomNumber;
        debug("refreshKey: ", refreshKey);
        this.setState({refreshKey});
      }


      componentDidUpdate(prevProps) {
        // console.log("this.props.data.id >>>>>>>>:", this.props.data.id);
        // console.log("prevProps.data.id  >>>>>>>>:", prevProps.data.id);
        // console.log("Source >>>>>>>>>>>> : ", this.props.data.yDataSet1Name);
        // console.log("this.props.data.statDate >>>>>>>>:", this.props.date);
        // console.log("this.lastUpdateDate  >>>>>>>>:", this.lastUpdateDate);
        if(this.props.data.id != prevProps.data.id || this.props.date != this.lastUpdateDate) // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
        {
          this.id = this.props.data.id;
          this.setState({id: this.id});
          this.setDataForChart();
          this.refreshChildKey(this.props.date);
          this.lastUpdateDate = this.props.date;
        }
      } 

      componentWillReceiveProps(props) {
        debug("^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ in componentWillReceiveProps");
        const { date, data } = this.props;
        debug("^^^^^^^^^ date:", date);
        debug("^^^^^^^^^ props.date", props.date);
        if (props.date !== date) {
          this.id = this.props.data.id;
          this.setState({id: this.id});
          this.setDataForChart();
          this.refreshChildKey(this.props.data.statDate);
        }
      }


render(){
    debug("############# In child render");
    
    return(
          <div>
              <AUX>
                  <ReactApexChart key={this.state.refreshKey} options={this.state.options} series={this.state.series} type="area" height="330" />
              </AUX>
          </div>
        );
    }
}

export default MultipleArea;   