const debugFlag = true;

//Funtion that is used in all components to debug only 
//to console log only when debug flag is on. 
export function debug(msg,parameter) {
    if(debugFlag){
        if (parameter != undefined){
            console.log(msg, parameter);
        }
        else{
            console.log(msg);
        }
    }
}