import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import $ from 'jquery';
import * as Auth from '../../Authentication/Auth';


class topbar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Tab: 'index', SubTab: '', MoreTab: '',
            dashboard_menu: false, ui_menu: false, comp_menu: false, page_menu: false, eco_menu: false,
            dropdownOpen: false,
            dropdownOpen1: false,
            dropdownOpenprofile: false,
            dropdownOpenbadge: false,
            toggleEmail: false,
            toggleForm: false,
            toggleChart: false,
            toggleTable: false,
            toggleIcon: false,
            toggleMap: false,
            toggleEmailTemp: false,
            isLoggedIn: false,
        };

        this.toggle = this.toggle.bind(this);
        this.toggle1 = this.toggle1.bind(this);
        this.toggleprofile = this.toggleprofile.bind(this);
        this.togglebadge = this.togglebadge.bind(this);

        let isLoggedIn = Auth.isLoggedIn();
        this.setState({ isLoggedIn: isLoggedIn })
        console.log("+++++++++++++++++++++++++++++++++++++ isLoggedIn = ", isLoggedIn);
    }

    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }
    toggle1() {
        this.setState(prevState => ({
            dropdownOpen1: !prevState.dropdownOpen1
        }));
    }
    toggleprofile() {
        this.setState(prevState => ({
            dropdownOpenprofile: !prevState.dropdownOpenprofile
        }));
    }
    togglebadge() {
        this.setState(prevState => ({
            dropdownOpenbadge: !prevState.dropdownOpenbadge
        }));
    }

    setActiveTab = (tab, subtab, moretab, toggleTab, e) => {
        this.setState({ Tab: tab, SubTab: subtab, MoreTab: moretab });
        if (subtab === 'email') { this.setState({ toggleEmail: toggleTab }) }
        else if (subtab === 'form') { this.setState({ toggleForm: toggleTab }) }
        else if (subtab === 'chart') { this.setState({ toggleChart: toggleTab }) }
        else if (subtab === 'table') { this.setState({ toggleTable: toggleTab }) }
        else if (subtab === 'icon') { this.setState({ toggleIcon: toggleTab }) }
        else if (subtab === 'map') { this.setState({ toggleMap: toggleTab }) }
        else if (subtab === 'emt') { this.setState({ toggleEmailTemp: toggleTab }) }
        else { }
    }

    componentDidMount() {
        $('.navbar-toggle').on('click', function (event) {
            $(this).toggleClass('open');
            $('#navigation').slideToggle(400);
        });

        $('li.has-submenu #dest_id').on('click', function (event) {
            if ($(window).width() < 992) {
                $('.navbar-toggle').toggleClass('open');
                $('#navigation').slideToggle(400);
            }
        });

        $('.navigation-menu>li').slice(-1).addClass('last-elements');

        $('.navigation-menu li.has-submenu a[href="#"]').on('click', function (e) {
            if ($(window).width() < 992) {
                e.preventDefault();
                $(this).parent('li').toggleClass('open').find('.submenu:first').toggleClass('open');
            }
        });

        $('.toggle-search').on('click', function () {
            var targetId = $(this).data('target');
            var $searchBar;
            if (targetId) {
                $searchBar = $(targetId);
                $searchBar.toggleClass('open');
            }
        });

    }

    togglescreen(e) {
        if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement) {  // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

    logOut = () => {
        console.log("Calling logout");
        this.setState({ isLoggedIn: false });
        Auth.logOut();
    }

    render() {
        return (
            <header id="topnav">
                {/* <div className="topbar-main">
                    <div className="container-fluid">



                        <div className="menu-extras topbar-custom">
                            <div className="search-wrap" id="search-wrap">
                                <div className="search-bar">
                                    <input className="search-input" type="search" placeholder="Search" />
                                    <Link to="#" className="close-search toggle-search" data-target="#search-wrap">
                                        <i className="mdi mdi-close-circle"></i>
                                    </Link>
                                </div>
                            </div>

                            <ul className="list-inline float-right mb-0">
                                <li className="list-inline-item dropdown notification-list">
                                    <Link className="nav-link waves-effect toggle-search" to="#" data-target="#search-wrap">
                                        <i className="mdi mdi-magnify noti-icon"></i>
                                    </Link>
                                </li>
                                <li className="list-inline-item dropdown notification-list hide-phone">
                                    <Link onClick={() => this.togglescreen()} className="nav-link waves-effect" to="#" id="btn-fullscreen">
                                        <i className="mdi mdi-fullscreen noti-icon"></i>
                                    </Link>
                                </li>
                                <li className="list-inline-item dropdown notification-list hide-phone">

                                    <Dropdown isOpen={this.state.dropdownOpen1} toggle={this.toggle1}>
                                        <DropdownToggle className="nav-link dropdown-toggle arrow-none waves-effect text-muted" tag="a">
                                            <span style={{ color: "#fff" }}>English</span> <img src="assets/images/flags/us_flag.jpg" className="ml-2" height="16" alt="" />
                                        </DropdownToggle>
                                        <DropdownMenu className='dropdown-menu dropdown-menu-right language-switch'>
                                            <a className="dropdown-item" href="#"><img src="assets/images/flags/germany_flag.jpg" alt="" height="16" /><span> German </span></a>
                                            <a className="dropdown-item" href="#"><img src="assets/images/flags/italy_flag.jpg" alt="" height="16" /><span> Italian </span></a>
                                            <a className="dropdown-item" href="#"><img src="assets/images/flags/french_flag.jpg" alt="" height="16" /><span> French </span></a>
                                            <a className="dropdown-item" href="#"><img src="assets/images/flags/spain_flag.jpg" alt="" height="16" /><span> Spanish </span></a>
                                            <a className="dropdown-item" href="#"><img src="assets/images/flags/russia_flag.jpg" alt="" height="16" /><span> Russian </span></a>
                                        </DropdownMenu>
                                    </Dropdown>
                                </li>
                                <li className="list-inline-item dropdown notification-list">

                                    <Dropdown isOpen={this.state.dropdownOpenbadge} toggle={this.togglebadge}>
                                        <DropdownToggle className="nav-link dropdown-toggle droptest arrow-none waves-effect" tag="a">
                                            <i className="ion-ios7-bell noti-icon"></i>
                                            <span className="badge badge-pill badge-danger noti-icon-badge">3</span>
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu dropdown-menu-right dropdown-menu-lg">
                                            <h6 className="dropdown-item-text">
                                                Notifications (258)
                                            </h6>
                                            <DropdownItem >
                                                <Scrollbars style={{ height: "200px" }} className="notification-item-list" >
                                                    <Link id="ex" to="#" className="dropdown-item notify-item active">
                                                        <div className="notify-icon bg-success"><i className="mdi mdi-cart-outline"></i></div>
                                                        <p className="notify-details">Your order is placed<span className="text-muted">Dummy text of the printing and typesetting industry.</span></p>
                                                    </Link>

                                                    <Link id="ex" to="#" className="dropdown-item notify-item">
                                                        <div className="notify-icon bg-warning"><i className="mdi mdi-message-text-outline"></i></div>
                                                        <p className="notify-details">New Message received<span className="text-muted">You have 87 unread messages</span></p>
                                                    </Link>

                                                    <Link id="ex" to="#" className="dropdown-item notify-item">
                                                        <div className="notify-icon bg-info"><i className="mdi mdi-martini"></i></div>
                                                        <p className="notify-details">Your item is shipped<span className="text-muted">It is a long established fact that a reader will</span></p>
                                                    </Link>

                                                </Scrollbars>
                                                <Link id="ex" to="#" className="dropdown-item text-center text-primary">
                                                    View all <i className="fi-arrow-right"></i>
                                                </Link>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>

                                </li>
                                <li className="list-inline-item dropdown notification-list">

                                    <Dropdown isOpen={this.state.dropdownOpenprofile} toggle={this.toggleprofile}>
                                        <DropdownToggle className="nav-link dropdown-toggle droptest arrow-none waves-effect nav-user" tag="a">
                                            <img src="assets/images/users/avatar-4.jpg" alt="user" className="rounded-circle" />
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem><i className="mdi mdi-account-circle m-r-5"></i> Profile</DropdownItem>
                                            <DropdownItem><i className="mdi mdi-lock-open-outline m-r-5"></i> Lock screen</DropdownItem>
                                            <DropdownItem onClick={() => this.logOut()} ><i className="mdi mdi-power text-danger"></i> Logout</DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>

                                </li>
                                <li className="menu-item list-inline-item">
                                    <Link to="#" className="navbar-toggle nav-link">
                                        <div className="lines">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </Link>
                                </li>

                            </ul>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div> */}

                <div className="navbar-custom">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-7">
                                <div id="navigation nav">


                                    <ul className="navigation-menu">

                                        <li>
                                            <Link to="/" >
                                                <img src="assets/images/Babvo_vector-file.svg" alt="" height="40" />
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to="Home"><i className="fa fa-home"></i>Home</Link>
                                        </li>

                                        {!Auth.isLoggedIn() ?
                                            <li>
                                                <Link to="Pages_login_2"><i className="fa-solid fa-arrow-right-to-arc"></i>Login</Link>
                                            </li> : <></>}

                                        {Auth.isLoggedIn() && (Auth.isAdmin() || Auth.isMaster()) ?
                                            <li>
                                                <Link to="Dashboard"><i className="mdi mdi-chart-line"></i>Dashboard</Link>
                                            </li> : <></>}

                                        {Auth.isLoggedIn() && Auth.isAdmin() ?
                                            <li className={this.state.Tab === 'pages' ? 'has-submenu active' : 'has-submenu'}>
                                                <Link to="#" className={this.state.Tab === 'pages' ? 'active-menu' : ''} onClick={() => this.setState({ page_menu: !this.state.page_menu })}><i className="mdi mdi-account-key"></i>Admin</Link>
                                                <ul className={this.state.page_menu ? "submenu megamenu open" : "submenu megamenu"} >
                                                    <li>
                                                        <ul>
                                                            {/* <li id="dest_id"><Link className={this.state.SubTab === 'pages_login' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'pages','pages_login','')} to="pages_login">Login</Link></li> */}
                                                            <li id="dest_id"><Link className={this.state.SubTab === 'pages_register' ? 'active-menu' : ''} onClick={this.setActiveTab.bind(this, 'pages', 'pages_register', '')} to="pages_register">Create Users</Link></li>
                                                            <li id="dest_id"><Link className={this.state.SubTab === 'pages_LockUser' ? 'active-menu' : ''} onClick={this.setActiveTab.bind(this, 'pages', 'pages_LockUser', '')} to="pages_LockUser">Lock User</Link></li>
                                                            <li id="dest_id"><Link className={this.state.SubTab === 'Pages_ResumeLockedUser' ? 'active-menu' : ''} onClick={this.setActiveTab.bind(this, 'pages', 'Pages_ResumeLockedUser', '')} to="Pages_ResumeLockedUser">Resume User</Link></li>
                                                            <li id="dest_id"><Link className={this.state.SubTab === 'Pages_resetPassword' ? 'active-menu' : ''} onClick={this.setActiveTab.bind(this, 'pages', 'Pages_resetPassword', '')} to="Pages_resetPassword">Reset Password</Link></li>


                                                            <li id="dest_id"><Link className={this.state.SubTab === 'Pages_ReleaseProxies' ? 'active-menu' : ''} onClick={this.setActiveTab.bind(this, 'pages', 'Pages_ReleaseProxies', '')} to="Pages_ReleaseProxies">Release Proxies</Link></li>
                                                            <li id="dest_id"><Link className={this.state.SubTab === 'Pages_dailyReport' ? 'active-menu' : ''} onClick={this.setActiveTab.bind(this, 'pages', 'Pages_dailyReport', '')} to="Pages_dailyReport">Daily Report</Link></li>

                                                        </ul>
                                                    </li>
                                                    <li>

                                                    </li>
                                                    <li>
                                                        <ul>

                                                            <li id="dest_id"><Link className={this.state.SubTab === 'Pages_loadProxies' ? 'active-menu' : ''} onClick={this.setActiveTab.bind(this, 'pages', 'Pages_loadProxies', '')} to="Pages_loadProxies">Import Proxies</Link></li>
                                                            <li id="dest_id"><Link className={this.state.SubTab === 'Pages_loadFirstNames' ? 'active-menu' : ''} onClick={this.setActiveTab.bind(this, 'pages', 'Pages_loadFirstNames', '')} to="Pages_loadFirstNames">Import First Names</Link></li>

                                                            <li id="dest_id"><Link className={this.state.SubTab === 'Pages_loadLastNames' ? 'active-menu' : ''} onClick={this.setActiveTab.bind(this, 'pages', 'Pages_loadLastNames', '')} to="Pages_loadLastNames">Import Last Names</Link></li>
                                                            <li id="dest_id"><Link className={this.state.SubTab === 'Pages_loadHobbies' ? 'active-menu' : ''} onClick={this.setActiveTab.bind(this, 'pages', 'Pages_loadHobbies', '')} to="Pages_loadHobbies">Import Hobbies</Link></li>
                                                            <li id="dest_id"><Link className={this.state.SubTab === 'Pages_loadUserTypes' ? 'active-menu' : ''} onClick={this.setActiveTab.bind(this, 'pages', 'Pages_loadUserTypes', '')} to="Pages_loadUserTypes">Import User Types</Link></li>

                                                            <li id="dest_id"><Link className={this.state.SubTab === 'User_Form' ? 'active-menu' : ''} onClick={this.setActiveTab.bind(this, 'pages', 'User_Form', '')} to="User_Form">Main Board</Link></li>



                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li> : <></>}

                                        {Auth.isLoggedIn() && (Auth.isUser() || Auth.isAdmin() || Auth.isMaster()) ?
                                            <li>
                                                <Link to="User_Form"><i className="mdi mdi-lan-connect"></i>Main Working Board</Link>
                                            </li> : <></>}
                                    </ul>
                                </div>


                            </div>

                            <div className="col-5">


                                <ul className="list-inline float-right mb-0">
                                    {/* <li className="list-inline-item dropdown notification-list">
                                        <Link className="nav-link waves-effect toggle-search" to="#" data-target="#search-wrap">
                                            <i className="mdi mdi-magnify noti-icon"></i>
                                        </Link>
                                    </li> */}
                                    <li className="list-inline-item dropdown notification-list hide-phone">
                                        <Link onClick={() => this.togglescreen()} className="nav-link waves-effect" to="#" id="btn-fullscreen">
                                            <i className="mdi mdi-fullscreen noti-icon"></i>
                                        </Link>
                                    </li>
                                    {/* <li className="list-inline-item dropdown notification-list hide-phone">

                                        <Dropdown isOpen={this.state.dropdownOpen1} toggle={this.toggle1}>
                                            <DropdownToggle className="nav-link dropdown-toggle arrow-none waves-effect text-muted" tag="a">
                                                <span style={{ color: "#fff" }}>English</span> <img src="assets/images/flags/us_flag.jpg" className="ml-2" height="16" alt="" />
                                            </DropdownToggle>
                                            <DropdownMenu className='dropdown-menu dropdown-menu-right language-switch'>
                                                <a className="dropdown-item" href="#"><img src="assets/images/flags/germany_flag.jpg" alt="" height="16" /><span> German </span></a>
                                                <a className="dropdown-item" href="#"><img src="assets/images/flags/italy_flag.jpg" alt="" height="16" /><span> Italian </span></a>
                                                <a className="dropdown-item" href="#"><img src="assets/images/flags/french_flag.jpg" alt="" height="16" /><span> French </span></a>
                                                <a className="dropdown-item" href="#"><img src="assets/images/flags/spain_flag.jpg" alt="" height="16" /><span> Spanish </span></a>
                                                <a className="dropdown-item" href="#"><img src="assets/images/flags/russia_flag.jpg" alt="" height="16" /><span> Russian </span></a>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </li> */}
                                    {/* <li className="list-inline-item dropdown notification-list">

                                        <Dropdown isOpen={this.state.dropdownOpenbadge} toggle={this.togglebadge}>
                                            <DropdownToggle className="nav-link dropdown-toggle droptest arrow-none waves-effect" tag="a">
                                                <i className="ion-ios7-bell noti-icon"></i>
                                                <span className="badge badge-pill badge-danger noti-icon-badge">3</span>
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-menu dropdown-menu-right dropdown-menu-lg">
                                                <h6 className="dropdown-item-text">
                                                    Notifications (258)
                                                </h6>
                                                <DropdownItem >
                                                    <Scrollbars style={{ height: "200px" }} className="notification-item-list" >
                                                        <Link id="ex" to="#" className="dropdown-item notify-item active">
                                                            <div className="notify-icon bg-success"><i className="mdi mdi-cart-outline"></i></div>
                                                            <p className="notify-details">Your order is placed<span className="text-muted">Dummy text of the printing and typesetting industry.</span></p>
                                                        </Link>

                                                        <Link id="ex" to="#" className="dropdown-item notify-item">
                                                            <div className="notify-icon bg-warning"><i className="mdi mdi-message-text-outline"></i></div>
                                                            <p className="notify-details">New Message received<span className="text-muted">You have 87 unread messages</span></p>
                                                        </Link>

                                                        <Link id="ex" to="#" className="dropdown-item notify-item">
                                                            <div className="notify-icon bg-info"><i className="mdi mdi-martini"></i></div>
                                                            <p className="notify-details">Your item is shipped<span className="text-muted">It is a long established fact that a reader will</span></p>
                                                        </Link>

                                                    </Scrollbars>
                                                    <Link id="ex" to="#" className="dropdown-item text-center text-primary">
                                                        View all <i className="fi-arrow-right"></i>
                                                    </Link>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>

                                    </li> */}
                                    <li className="list-inline-item dropdown notification-list">

                                        <Dropdown isOpen={this.state.dropdownOpenprofile} toggle={this.toggleprofile}>
                                            <DropdownToggle className="nav-link dropdown-toggle droptest arrow-none waves-effect nav-user" tag="a">
                                                <img src="assets/images/users/avatar-4.jpg" alt="user" className="rounded-circle" />
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem><i className="mdi mdi-account-circle m-r-5"></i> Profile</DropdownItem>
                                                <DropdownItem><i className="mdi mdi-lock-open-outline m-r-5"></i> Lock screen</DropdownItem>
                                                <DropdownItem onClick={() => this.logOut()} ><i className="mdi mdi-power text-danger"></i> Logout</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>

                                    </li>
                                    <li className="menu-item list-inline-item">
                                        <Link to="#" className="navbar-toggle nav-link">
                                            <div className="lines">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>
                                        </Link>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default withRouter(topbar);