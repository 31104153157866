import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { Link } from 'react-router-dom';
import * as Api from '../../../Api/Axios';
import * as Utils from '../../../Utilities/Utils';

class ResetPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            basic: false, with_title: false, success_msg: false, confirm_msg: false, success_dlg: false, error_dlg: false,
            dynamic_title: '', dynamic_description: '', confirm_both: false, img_header: false, img_html: false, custom_div: false,
            emailtxt: '', emailerr: '', step1: false, step1_txt: '', step2: false, step2_txt: '', step3: false, step3_txt: '', step_queue: false,
            final_queue: false, current_ip: '219.91.239.22', close_timer: false, timeralert: null,
            errorField: '',
            employeeId_err: '',
            userDetails: [],
            password: '',
            passwd_err: '',
            username_err: '',
            userpassword_err: '',
        };
    }

    userDetails = {
        employeeId: -1,
        username: '',
        userpassword: '',
        userRole: 1,
    }

    userToBlock = {
        employeeId: -1
    }

    isCopied = false;


    isInputValid = false;

    componentDidMount() {
        this.getEmployeeList();
        this.setPassword();
    }

    getEmployeeList = async () => {
        let res = await Api.postRequest("getEmployeeList.php", { statusToGet: 'A' });
        console.log("result of getEmployeeList", res);
        if (res.data.status == 201) {
            this.userDetails = [...res.data.response.data];

            console.log("userDetails: ", this.userDetails);
            if (this.userDetails.length > 0) {
                this.setState({ userDetails: this.userDetails });
            }
        }
    }

    onChange = (event) => {
        let errorAttribute = event.target.id + "_err";
        this.userDetails[event.target.id] = event.target.value;
        console.log("userDetails : ", this.userDetails);
        this.setState({ [errorAttribute]: '' });
    }

    setPassword() {
        let tmpPassword = Math.random().toString(36).substring(2);
        let specialCharacterBank = ['_', '#', '!', '&'];
        let index = Math.floor(Math.random() * (3));
        let extention = Math.floor(Math.random() * (89)) + 10;
        let specialCharacter = specialCharacterBank[index];
        let password = tmpPassword.substring(0, 1).toUpperCase() + tmpPassword.substring(1) + specialCharacter + extention;
        console.log("password: ", password);
        this.setState({ password });
    }


    validateInput = () => {
        this.validateEmployeeId(this.userDetails.employeeId);
        this.setValidationInd();
    }

    handleCopyClick = (e) => {
        Utils.copyTextToClipboard(e.target.value)
            .then(() => {
                this.isCopied = true;
                setTimeout(() => {
                    this.isCopied = false;
                }, 1000);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    validateEmployeeId(employeeId) {

        this.inputValidations.employeeIdValid = false;
        console.log("employeeId:", employeeId);
        if (employeeId == -1) {
            console.log("setting the error to : Employee Id is required");
            this.setState({ employeeId_err: "Employee Id is required" });
        } else if (Number(employeeId) == NaN) {
            console.log("setting the error to : Please put a valid employee Id");
            this.setState({ employeeId_err: "Please put a valid employee Id" });
            this.inputValidations.employeeIdValid = true;
        } else if (employeeId < 1000 || employeeId > 999999) {
            console.log("setting the error to : employee Id is not valid");
            this.setState({ employeeId_err: "employee Id is not valid" });
        } else {
            this.inputValidations.employeeIdValid = true;
        }

    }

    setValidationInd = () => {
        this.isInputValid = this.inputValidations.employeeIdValid;
    }

    resetPassword = async () => {
        let res = await Api.postRequest("resetPassword.php", {
            username: this.userDetails.username,
            password: this.userDetails.userpassword,
            newPassword: this.state.password,
            userId: this.userDetails.employeeId
        });
        if (res.data.status == 201) {
            console.log("Password is set correctly");
        } else {
            console.log("Password is not set correctly");
        }
    }




    render() {
        return (
            <AUX>
                <div className="accountbg"></div>
                <div className="wrapper-page">

                    <div className="card">
                        <div className="card-body">

                            <h3 className="text-center m-0">
                                <Link to="/" onClick={() => this.props.UpdateLoginAgain()} className="logo logo-admin"><img src="assets/images/logo.png" height="30" alt="logo" /></Link>
                            </h3>

                            <div className="p-3">

                                <form className="form-horizontal m-t-30" action="index.html">

                                    <div className="form-group">

                                        <label for="employeeId">Employee</label>
                                        <select className="form-control" id="employeeId" placeholder="Enter Employee" onChange={(e) => this.onChange(e)}>
                                            {
                                                this.state.userDetails ?
                                                    this.state.userDetails.map(user => {
                                                        return <option value={user.employeeId} >{user.employeeName + ' - ' + user.employeeId}</option>
                                                    }) : ''
                                            }

                                        </select>
                                        <span className="error-span" id="err">{this.state.gender_err}</span>
                                    </div>

                                    <div className="form-group">
                                        <label>New Password</label>
                                        <input readOnly type="text" className="form-control" value={this.state.password} onClick={(e) => this.handleCopyClick(e)} placeholder="Enter Password" />
                                        <span id="err">{this.state.passwd_err}</span>
                                    </div>

                                    <div className="form-group">
                                        <label for="username">Admin user name</label>
                                        <input type="username" className="form-control" id="username" placeholder="Enter username" onChange={(e) => this.onChange(e)} />
                                        <span className="error-span" id="err">{this.state.username_err}</span>
                                    </div>

                                    <div className="form-group">
                                        <label for="userpassword">Admin Password</label>
                                        <input type="password" className="form-control" id="userpassword" placeholder="Enter password" onChange={(e) => this.onChange(e)} />
                                        <span className="error-span" id="err">{this.state.userpassword_err}</span>
                                    </div>

                                    <div className="form-group row m-t-20">
                                        <div className="col-12 text-right">
                                            <button className="btn btn-primary w-md waves-effect waves-light" type="button" onClick={() => this.resetPassword()}>Reset Password</button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>

                    <div className="m-t-40 text-center">
                        <p className="text-white">Already have an account ? <Link to="pages_login" className="font-500 font-14 text-white font-secondary"> Login </Link> </p>
                        <p className="text-white">©  {new Date().getFullYear() - 1} -  {new Date().getFullYear()} Babvo. Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesbrand</p>
                    </div>

                </div>

            </AUX>
        );
    }
}

export default ResetPassword;