import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actionTypes from '../../../store/action';
import * as Api from '../../../Api/Axios';
import SweetAlert from 'react-bootstrap-sweetalert';

class UserStatusUpdater extends Component {

    constructor(props) {
        super(props);
        this.state = {
            basic: false, with_title: false, success_msg: false, confirm_msg: false, success_dlg: false, error_dlg: false,
            dynamic_title: '', dynamic_description: '', confirm_both: false, img_header: false, img_html: false, custom_div: false,
            emailtxt: '', emailerr: '', step1: false, step1_txt: '', step2: false, step2_txt: '', step3: false, step3_txt: '', step_queue: false,
            final_queue: false, current_ip: '219.91.239.22', close_timer: false, timeralert: null,
            errorField: '',
            employeeId_err: '',
            userDetails: []
        };

        switch (this.statusToGet) {
            case 'A':
                this.buttonCaption = 'Lock User';
                this.userToUpdate.newStatus = 'C';
                this.warningMessageTitle = 'Are you sure you want to lock the user?';
                this.successMessageTitle = 'The user is locked successfully in the system!';
                break;
            default:
                this.buttonCaption = 'Resume User';
                this.userToUpdate.newStatus = 'A';
                this.warningMessageTitle = 'Are you sure you want to resume the user?';
                this.successMessageTitle = 'The user is resumed successfully in the system!';
                break;
        }
    }

    buttonCaption = '';

    userDetails = []

    userToUpdate = {
        employeeId: -1,
        newStatus: ''
    }

    statusToGet = this.props.userStatus;

    inputValidations = {
        employeeIdValid: false,
    }

    isInputValid = false;

    warningMessageTitle = '';
    successMessageTitle = '';




    componentDidMount() {
        this.getEmployeeList();
    }

    getEmployeeList = async () => {
        let res = await Api.postRequest("getEmployeeList.php", { statusToGet: this.statusToGet });
        console.log("result of getEmployeeList", res);
        if (res.data.status === 201) {
            this.userDetails = [...res.data.response.data];

            console.log("userDetails: ", this.userDetails);
            if (this.userDetails.length > 0) {
                this.setState({ userDetails: this.userDetails });
            }
        }
    }

    onChange = (event) => {
        let errorAttribute = event.target.id + "_err";
        this.userToUpdate[event.target.id] = event.target.value;
        console.log("userToUpdate : ", this.userToUpdate);
        this.setState({ [errorAttribute]: '' });
    }

    validateNumeric = (e) => {
        const re = /[0-9]+/g;
        if (!re.test(e.key)) {
            e.preventDefault();
        }
    }


    validateInput = () => {
        this.validateEmployeeId(this.userToUpdate.employeeId);
        this.setValidationInd();
    }

    validateEmployeeId(employeeId) {

        this.inputValidations.employeeIdValid = false;
        console.log("employeeId:", employeeId);
        if (employeeId === -1) {
            console.log("setting the error to : Employee Id is required");
            this.setState({ employeeId_err: "Employee Id is required" });
        } else if (isNaN(Number(employeeId))) {
            console.log("setting the error to : Please put a valid employee Id");
            this.setState({ employeeId_err: "Please put a valid employee Id" });
            this.inputValidations.employeeIdValid = true;
        } else if (employeeId < 1000 || employeeId > 999999) {
            console.log("setting the error to : employee Id is not valid");
            this.setState({ employeeId_err: "employee Id is not valid" });
        } else {
            this.inputValidations.employeeIdValid = true;
        }

    }

    setValidationInd = () => {
        this.isInputValid = this.inputValidations.employeeIdValid;
    }

    lockUser = () => {
        this.validateInput();
        console.log("isInputValid:", this.isInputValid);
        console.log("inputValidations", this.inputValidations);
        if (this.isInputValid) {
            this.setState({ confirm_msg: true });
        }
    }

    async lockUserAfterConfirm() {


        let url = "adminLockUser.php";

        console.log("url=", url)

        if (this.isInputValid) {
            let res = await Api.postRequest(url, this.userToUpdate);
            console.log("res=", res)
            switch (res.data.status) {
                case 201:
                    this.setState({ confirm_msg: false, success_dlg: true, dynamic_title: 'Deleted', dynamic_description: 'User had been locked!' })
                    this.setState({ success_msg: true });
                    break;
                case 400:
                    this.setState({ errorField: res.data.message });
                    this.setState({ img_html: true });
                    break;
                default:
                    break;
            }
        }
    }



    render() {
        return (
            <AUX>

                <div className="col-xl-3 col-lg-4 col-sm-6 m-b-30">
                    {this.state.img_html ?
                        <SweetAlert
                            title={<span><u>Error</u>!</span>}
                            warning
                            onConfirm={() => this.setState({ img_html: false })} >
                            {this.state.errorField}!
                        </SweetAlert> : null}
                </div>

                <div className="col-xl-3 col-lg-4 col-sm-6 m-b-30">
                    {this.state.success_msg ?
                        <SweetAlert
                            title={this.successMessageTitle}
                            success
                            confirmBtnBsStyle="success"
                            onConfirm={() => this.setState({ success_msg: false })}>
                        </SweetAlert> : null}
                </div>

                <div className="col-xl-3 col-lg-4 col-sm-6 m-b-30">
                    {this.state.confirm_msg ?
                        <SweetAlert
                            title={this.warningMessageTitle}
                            warning
                            showCancel
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            onConfirm={() => this.lockUserAfterConfirm()}
                            onCancel={() => this.setState({ confirm_msg: false })} >
                        </SweetAlert> : null}
                </div>

                <div className="accountbg"></div>
                <div className="wrapper-page">

                    <div className="card">
                        <div className="card-body">

                            <h3 className="text-center m-0">
                                <Link to="/" onClick={() => this.props.UpdateLoginAgain()} className="logo logo-admin"><img src="assets/images/logo.png" height="30" alt="logo" /></Link>
                            </h3>

                            <div className="p-3">

                                <form className="form-horizontal m-t-30" action="index.html">

                                    <div className="form-group">
                                        {this.statusToGet === 'A' ?
                                            <label for="employeeId">Employee to lock</label> : <label for="employeeId">Employee to resume</label>}
                                        <select className="form-control" id="employeeId" placeholder="Enter Employee" onChange={(e) => this.onChange(e)}>
                                            <option value={0} >Please select</option>
                                            {
                                                this.state.userDetails ?
                                                    this.state.userDetails.map(user => {
                                                        return <option value={user.employeeId} >{user.employeeName + ' - ' + user.employeeId}</option>
                                                    }) : ''
                                            }

                                        </select>
                                        <span className="error-span" id="err">{this.state.gender_err}</span>
                                    </div>

                                    <div className="form-group row m-t-20">
                                        <div className="col-12 text-right">
                                            <button className="btn btn-primary w-md waves-effect waves-light" type="button" onClick={() => this.lockUser()}>{this.buttonCaption}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>

                    <div className="m-t-40 text-center">
                        <p className="text-white">Already have an account ? <Link to="pages_login" className="font-500 font-14 text-white font-secondary"> Login </Link> </p>
                        <p className="text-white">©  {new Date().getFullYear() - 1} -  {new Date().getFullYear()} Babvo. Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesbrand</p>
                    </div>

                </div>

            </AUX>
        );
    }
}


const mapStatetoProps = state => {
    return {
        loginpage: state.ui_red.loginpage
    };
}

const mapDispatchtoProps = dispatch => {
    return {
        UpdateLogin: () => dispatch({ type: actionTypes.LOGINPAGE, value: true }),
        UpdateLoginAgain: () => dispatch({ type: actionTypes.LOGINPAGE, value: false })
    };
}

export default connect(mapStatetoProps, mapDispatchtoProps)(UserStatusUpdater);