import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import * as Api from '../../../Api/Axios';
import 'moment-timezone';
import * as Auth from '../../../Authentication/Auth';
import { debug } from '../../MainContent/Components/component_debug';
import * as Utils from '../../../Utilities/Utils';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

class Form_user extends Component {

  constructor() {
    super();
    this.state = {
      email: '',
      email_err: '',
      provider: '',
      provider_err: '',
      ip: '',
      ip_err: '',
      port: '',
      port_err: '',
      firstName: '',
      firstName_err: '',
      lastName: '',
      lastName_err: '',
      birthdate: '',
      birthdate_err: '',
      userName: '',
      userName_err: '',
      password: '',
      password_err: '',
      phone: '',
      phone_err: '',
      hobby: '',
      hobby_err: '',
      textToCopy: '',
      userId: -1,
      userTypes: [],
      userTypes_err: '',
      userType: 'Please select',
      employee: {},
      data: {},
      getNewRecord: false,
    }

    this.initDataPerHour();

  }

  userDetails = {
    email: '',
    provider: '',
    ip: '',
    port: '',
    firstName: '',
    lastName: '',
    birthdate: '',
    userName: '',
    password: '',
    phone: '',
    userType: '',
    hobby: '',
    recopy: true
  }

  userDetailsEmpty = {
    email: '',
    provider: '',
    ip: '',
    port: '',
    firstName: '',
    lastName: '',
    birthdate: '',
    userName: '',
    password: '',
    phone: '',
    userType: '',
    hobby: '',
  }

  employee = {
    id: -1,
    name: "",
    activityToGet: "Activate",
    statDate: '',
    dataPerHour: [],
    dataPerHourForTable: [],
    totalActivations: 0,
    firstActivateHour: 0,
    lastActivateHour: 0,
    totalDailyBlock: 0,
  }

  userTypes = [];

  data = {
    columns: [
      {
        label: 'Data Type',
        field: 'dataType',
        sort: 'asc',
        width: 150
      }],
    rows: [{
      dataType: 'Total Per Hour',
      A: 0,
      B: 0,
      C: 0,
      D: 0,
      E: 0,
      F: 0,
      G: 0,
      H: 0,
      I: 0,
      J: 0,
      K: 0,
      L: 0,
      M: 0,
      N: 0,
      O: 0,
      P: 0,
      Q: 0,
      R: 0,
      S: 0,
      T: 0,
      U: 0,
      V: 0,
      W: 0,
      X: 0,
    }]
  }

  phoneNumber = '';
  userEmail = '';
  isCopied = false;
  isActivatedOrBlocked = false;


  componentDidMount() {
    debug(")))))))))))) IN COMPONENT DID MOUNT (((((((((((((((")
    this.getNewRecord(false);
    this.getUserTypeList();
    this.setState({ userId: Auth.getEmployeeId() });
    this.setCurrentDate();
    this.getUserDetails();
    this.getEmployeeHourlyStatistics();
    this.getEmployeeBlockStatistics();
  }

  resetState = () => {
    this.setState({
      email: '',
      email_err: '',
      provider: '',
      provider_err: '',
      ip: '',
      ip_err: '',
      port: '',
      port_err: '',
      firstName: '',
      firstName_err: '',
      lastName: '',
      lastName_err: '',
      birthdate: '',
      birthdate_err: '',
      userName: '',
      userName_err: '',
      password: '',
      password_err: '',
      phone: '',
      phone_err: '',
      hobby: '',
      hobby_err: '',
      textToCopy: '',
      textAreaText: '',
      userId: -1
    });
    let userTypeBackup = this.userDetails.userType;
    this.userDetails = { ...this.userDetailsEmpty };
    this.userDetails.userType = userTypeBackup;
    localStorage.setItem('userDetails', '');
    this.setState({ userId: Auth.getEmployeeId() });
    this.userDetails.recopy = true;

  }

  initDataPerHour = () => {
    let initElement = {
      hourInDay: 0,
      countPerHour: 0,
    }
    for (let i = 0; i < 24; i++) {
      this.employee.dataPerHour.push({ ...initElement });
    }
  }

  setCurrentDate = () => {
    let current = new Date();
    let year = current.getFullYear();
    let month = (current.getMonth() + 1) < 9 ? "0" + (current.getMonth() + 1) : (current.getMonth() + 1);
    let day = current.getDate() < 9 ? "0" + current.getDate() : current.getDate();
    let strDate = year + "-" + month + "-" + day;
    this.setState({ currentDate: strDate });
    this.employee.statDate = strDate;
  }

  getEmployeeHourlyStatistics = async () => {

    if (this.employee.statDate === '' || this.employee.id === -1) return;
    this.employee.totalActivations = 0;
    let res = await Api.postRequest("dashboardGetEmployeeData.php", { activityToGet: this.employee.activityToGet, statDate: this.employee.statDate, employeeId: this.employee.id });

    let index;
    if (res && res.data && res.data.response && res.data.response.data && res.data.response.data.length > 0) {
      res.data.response.data.map(dataElement => {
        index = Number(dataElement.hourInDay);
        if (!isNaN(index)) {
          this.employee.dataPerHour[index].countPerHour = Number(dataElement.countPerHour);
          this.employee.dataPerHour[index].hourInDay = index;
          this.employee.totalActivations += Number(dataElement.countPerHour);
          if (this.employee.dataPerHour[index].countPerHour > 0) {
            if (this.employee.firstActivateHour === 0) {
              this.employee.firstActivateHour = index;
            }
            this.employee.lastActivateHour = index + 1;
          }

        }
      })
      if (this.employee.lastActivateHour > this.employee.firstActivateHour) {
        this.employee.dataPerHourForTable = this.employee.dataPerHour.slice(this.employee.firstActivateHour, this.employee.lastActivateHour);
      }
    }
    this.setState({ employee: { ...this.employee } });
  }

  getEmployeeBlockStatistics = async () => {
    console.log("Calling getEmployeeBlockStatistics =====>");
    if (this.employee.statDate === '' || this.employee.id === -1) return;
    this.employee.totalActivations = 0;
    let res = await Api.postRequest("dashboardGetEmployeeData.php", { activityToGet: 'Block', statDate: this.employee.statDate, employeeId: this.employee.id });
    let totalDailyBlock = 0;
    if (res && res.data && res.data.response && res.data.response.data && res.data.response.data.length > 0) {
      res.data.response.data.map(dataElement => {
        totalDailyBlock += Number(dataElement.countPerHour);
      });
      this.employee.totalDailyBlock = totalDailyBlock;
    }
    this.setState({ employee: { ...this.employee } });
    this.setState({});

  }


  setTableData = () => {
    let startTheFill = false;
    let hourCol = {};
    let countPerHour;
    this.employee.dataPerHour.map(hourlyStat => {
      if (hourlyStat.countPerHour > 0) startTheFill = true;
      if (startTheFill) {
        hourCol.label = hourlyStat.hourInDay;
        hourCol.field = 'hour';
        hourCol.sort = 'asc';
        hourCol.width = 150;
      }
      countPerHour = hourlyStat.countPerHour;
      this.data.columns.push(hourCol);
    })

    this.setState({ data: this.data });
  }

  getUserDetails = () => {
    let userId = Auth.getEmployeeId();
    this.employee.id = userId;
  }

  handleCopyClick = (e) => {
    console.log("e.target.value :", e.target.value);
    this.copyText(e.target.value)
  };

  copyText(text) {
    this.userDetails.recopy = true;
    Utils.copyTextToClipboard(text)
      .then(() => {
        console.log("Set is copied to true");
        this.isCopied = true;
        setTimeout(() => {
          console.log("Timeout, setting to false");
          this.isCopied = false;
        }, 1000);
      })
      .catch((err) => {
        console.log("There's an error: ", err);
      });
  }



  getNewRecord = async (byRequest) => {
    this.userDetails.recopy = true;
    this.isActivatedOrBlocked = false;
    this.setState({ getNewRecord: false });
    if (localStorage.getItem('userDetails') && !byRequest) {
      this.setRecoverValues();
    } else {
      this.resetState();
      await this.getRandomProxy();
      await this.getFirstName();
      await this.getLastName();
      this.getBirthDate();
      this.setUserName();
      await this.getHobby();
      this.setPassword();
      localStorage.setItem('userDetails', JSON.stringify(this.userDetails));
    }
    this.setStateValues();
  }

  generateNewRecord = () => {
    this.userDetails.recopy = true;
    localStorage.setItem('userDetails', '');
    this.setState({ getNewRecord: true });
    if (this.isActivatedOrBlocked){
      this.getNewRecord(true);
    }
  }

  setRecoverValues = () => {
    this.userDetails.recopy = true;
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    this.setStateValues();
  }

  setStateValues = () => {
    this.setState({
      email: this.userDetails.email,
      provider: this.userDetails.provider,
      ip: this.userDetails.ip,
      port: this.userDetails.port,
      firstName: this.userDetails.firstName,
      lastName: this.userDetails.lastName,
      birthdate: this.userDetails.birthdate,
      userName: this.userDetails.userName,
      password: this.userDetails.password,
      phone: this.userDetails.phone,
      userType: this.userDetails.userType,
      hobby: this.userDetails.hobby,
    }, () => {
      this.setTextArea();
    });
  }

  getFirstName = async () => {
    let firstNameRes = await Api.postRequest("getFirstLastName.php", { tableName: "firstNames", fieldName: "firstName" });
    if (firstNameRes && firstNameRes.data && firstNameRes.data.response && firstNameRes.data.response.length > 0) {
      let firstName = firstNameRes.data.response[0].name;
      this.userDetails.firstName = firstName;

    }

  }

  getLastName = async () => {
    let lastNameRes = await Api.postRequest("getFirstLastName.php", { tableName: "lastNames", fieldName: "lastName" });
    if (lastNameRes && lastNameRes.data && lastNameRes.data.response && lastNameRes.data.response.length > 0) {
      let lastName = lastNameRes.data.response[0].name;
      this.userDetails.lastName = lastName;
    }

  }

  getHobby = async () => {
    let hobbies = await Api.postRequest("getRandomHobbies.php", {});
    console.log("===================>Hobbies", hobbies);
    if (hobbies && hobbies.data && hobbies.data.response && hobbies.data.response.length > 0) {
      let hobby = hobbies.data.response[0].hobby;
      console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> hobby:", hobby);
      this.userDetails.hobby = hobby;
      this.setState({ hobby });
    }
  }

  getBirthDate = () => {
    let yearValue = 31556926000;
    let ageInYears = Math.floor(Math.random() * (60 - 18 + 1) + 18);
    let currentDate = new Date().getTime();
    let yearOfBirthEpoch = currentDate - (ageInYears * yearValue) + Math.floor(Math.random() * 31556926000);
    let birthdate = new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(yearOfBirthEpoch);
    this.userDetails.birthdate = birthdate;

  }

  setTextArea = () => {
    console.log("========================>>>>>>>>>>>>>> COPY");
    if (this.userDetails.recopy && this.validateInput("Copy")) {
      let textToCopy = '';
      this.setState({ textAreaText: textToCopy });
      if (this.state.userType !== '') {
        // textToCopy = "User Type: " + this.state.userType + "\n";
        textToCopy = this.state.firstName + " " + this.state.lastName + "\n";
        textToCopy += this.state.userName + "\n";
        textToCopy += this.state.password + "\n";
        textToCopy += this.state.ip + "\n";
        textToCopy += this.state.port + "\n";
        // textToCopy += this.state.provider + "\n";
        textToCopy += this.state.birthdate + "\n";
        textToCopy += this.state.phone + "\n";
        textToCopy += this.state.email + "\n";
        this.setState({ textAreaText: textToCopy });
        this.copyText(textToCopy);
        this.userDetails.recopy = false;
      }
    }
  }

  setUserName = () => {
    let userName = this.userDetails.lastName;
    userName += this.userDetails.firstName.slice(0, 2);
    userName += Math.floor(Math.random() * 1000);
    userName = userName.toLocaleLowerCase();
    this.userDetails.userName = userName;

  }

  setPassword = () => {
    let tmpPassword = Math.random().toString(36).substring(2);
    let specialCharacterBank = ['_', '#', '!', '&'];
    let index = Math.floor(Math.random() * (3));
    let extention = Math.floor(Math.random() * (89)) + 10;
    // let specialCharacter = specialCharacterBank[index];
    let password = tmpPassword.substring(0, 1).toUpperCase() + tmpPassword.substring(1) + extention;
    this.userDetails.password = password;
  }

  getRandomProxy = async () => {
    let proxyRes = await Api.getRequest("getRandomProxy.php");
    if (proxyRes && proxyRes.data && proxyRes.data.response && proxyRes.data.response.length > 0) {
      let provider = proxyRes.data.response[0].provider;
      let ip = proxyRes.data.response[0].IP;
      let port = proxyRes.data.response[0].port;

      this.userDetails.provider = provider;
      this.userDetails.ip = ip;
      this.userDetails.port = port;
      this.setStateValues();

      localStorage.setItem('userDetails', JSON.stringify(this.userDetails));

    }
  }

  getUserTypeList = async () => {
    let res = await Api.postRequest("getUserTypeList.php", {});
    if (res && res.data && res.data.status === 201) {
      this.userTypes = [...res.data.response.data];

      if (this.userTypes.length > 0) {
        this.setState({ userTypes: this.userTypes });
      }
    }
  }


  copyCodeToClipboard = () => {
    let mytext = this.state.textToCopy;
    mytext.select();
    document.execCommand("copy");
  }

  copyTextAreaToClipboard = () => {

  }

  activateProxy = async () => {
    if (!this.validateInput("Activate")) {
      alert("Some of the input is not valid");
      return;
    }
    if (await this.doesEmailExist()) {
      alert("The email is already used by another user, please change it!");
      return;
    }

    this.updateActivity("Activate");
  }




  validateInput = (activity) => {
    if (activity === "Activate") {
      if (!this.validateEmail()) {
        console.log("email is invalid"); return false
      };
    }
    if (!this.validateUserType()) { console.log("user type is invalid"); return false };
    if (!this.validateProxy()) { console.log("proxy is invalid"); return false };
    if (!this.validateUserName()) { console.log("user name is invalid"); return false };
    if (!this.validatePassword()) { console.log("password is invalid"); return false };
    if (!this.validateFirstName()) { console.log("first name is invalid"); return false };
    if (!this.validateLastName()) { console.log("last name is invalid"); return false };
    if (!this.validateBirthDate()) { console.log("birth date is invalid"); return false };
    if (!this.validatePhoneNumber()) { console.log("Phone number is invalid"); return false };
    return true;
  }

  doesEmailExist = async () => {
    let res = await Api.postRequest("isEmailUsed.php", { useremail: this.state.email });
    if (res && res.data && res.data.status) {
      console.log("res.data.status: ", res.data.status);
      switch (res.data.status) {
        case 201:
          console.log("Case 201");
          return false;
        case 400:
          console.log("Case 400");
          return true;
        default:
          console.log("Case default");
          return false;
      }

    }
  }



  // var EmailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   if (e.target.value === '')
  //     
  //   else if (EmailReg.test(e.target.value))
  //     this.setState({ email_err: '' });
  //   else
  //     this.setState({ email_err: 'Enter Valid Email' });
  //   this.setState({ email: e.target.value }, () => {
  //     this.setTextArea();
  //   });


  validateEmail = () => {
    var EmailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    EmailReg.test(this.state.email);
    if (this.state.email === '') {
      this.setState({ email_err: 'Required Field' });
      return false;
    }
    else {
      if (!EmailReg.test(this.state.email)) {
        this.setState({ email_err: 'Enter Valid Email' });
        return false;
      }
    }
    return true;
  }

  validateUserType = () => {
    if (this.state.userType === '') return false;
    return true;
  }

  validateProxy = () => {
    if (this.state.port === '' || (this.state.port !== '' && this.state.port.length < 4 && Number(this.state.port) !== this.state.port)) {
      return false;
    }

    if (this.state.ip === '' || (this.state.ip !== '' && !(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(this.state.ip)))) {
      return false
    }
    if (this.state.provider === '') return false;
    return true;
  }

  validateUserName = () => {
    if (this.state.userName === '' || (this.state.userName !== '' && this.state.userName.length < 7)) return false;
    return true;
  }

  validatePassword = () => {
    if (this.state.password === '' || (this.state.password !== '' && this.state.password.length < 8)) return false;
    return true;
  }

  validateFirstName = () => {
    if (this.state.firstName === '') return false;
    return true;
  }

  validateLastName = () => {
    if (this.state.lastName === '') return false;
    return true;
  }

  validateBirthDate = () => {
    if (this.state.birthdate === '') return false;
    return true;
  }

  validatePhoneNumber = () => {
    const pattern = /^[0]{1}[0-9]{2}-[0-9]{4}-[0-9]{3}|[0]{1}[0-9]{9}|^[\+]?[9]{1}[7]{1}[2]{1}[0-9]{2}-[0-9]{4}-[0-9]{3}|^[\+]?[9]{1}[7]{1}[2]{1}[0-9]{9}$/;
    if (this.state.phone === '' || this.state.phone.length === 0) {
      this.setState({ phone_err: "Phone number is required" });
      return false;
    } else if (!pattern.test(this.state.phone)) {
      this.setState({ phone_err: "incorrect phone number format" });
      return false;
    } else {
      return true;
    }
  }

  blockProxy = () => {
    if (this.validateInput("Block")) {
      this.updateActivity("Block");
    }else{
      alert("Some of the input is not valid");
    }
  }

  updateActivity = (activity) => {
    this.updateProxyInDB(activity);
    this.logEmployeeActivity(activity);
    this.resetState();
    this.isActivatedOrBlocked = true;
  }

  updateProxyInDB = async (activityMode) => {
    let updateObj = {
      updateMode: activityMode,
      provider: this.state.provider,
      ip: this.state.ip,
      port: this.state.port
    }
    await Api.postRequest("updateActivateOrBlockProxy.php", updateObj);
  }

  logEmployeeActivity = async (activityMode) => {
    let updateObj = {
      userId: this.state.userId,
      provider: this.state.provider,
      ip: this.state.ip,
      port: this.state.port,
      email: this.state.email,
      userName: this.state.userName,
      fullName: this.state.firstName + ' ' + this.state.lastName,
      password: this.state.password,
      userType: this.state.userType,
      activity: activityMode
    }

    Api.postRequest("updateEmployeePerformance.php", updateObj);
    if (activityMode === "Activate") {
      this.getEmployeeHourlyStatistics();
    } else {
      this.getEmployeeBlockStatistics();
    }
  }


  handleChangeEmail = (e) => {
    this.userDetails.recopy = true;
    this.userDetails.email = e.target.value;
    this.setState({ email: this.userDetails.email });
  }


  handleChangePhone = (e) => {
    this.userDetails.recopy = true;
    let phone = e.target.value;
    this.setState({ phone, phone_err: '' }, () => {
      this.setTextArea();
      this.userDetails.phone = phone;
      localStorage.setItem('userDetails', JSON.stringify(this.userDetails));
    });
  }

  handleChangeUserType = (e) => {
    this.userDetails.recopy = true;
    let userType = e.target.value;
    this.setState({ userType }, () => {
      this.setTextArea();
      this.userDetails.userType = userType;
      localStorage.setItem('userDetails', JSON.stringify(this.userDetails));
    });
  }



  render() {
    return (
      <AUX key={1}>

        <div className="col-xl-3 col-lg-4 col-sm-6 m-b-30">
          {this.state.getNewRecord && !this.isActivatedOrBlocked?
            <SweetAlert
              title="Please make sure you reported this user before generating a new user"
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => this.getNewRecord(true)} 
              onCancel={() => this.setState({ getNewRecord: false })}>
              
            </SweetAlert> : null}
        </div>


        <div className="wrapper">
          <div className="container-fluid">
            {/* 
            <div className="row">

            </div> */}

            <div className="row">
              <div className="col-lg-5">
                <div className="card m-b-20">
                  <div className="card-body">


                    <form className="" action="#">

                      <div className="form-group row">
                        <label for="example-date-input" className="col-sm-2 col-form-label">User Type</label>
                        <div className="col-sm-10">
                          <select className="form-control" id="userType" placeholder="Enter User Type" onChange={(e) => this.handleChangeUserType(e)}>
                            <option value={this.state.userType} selected >{this.state.userType}</option>
                            {
                              this.state.userTypes ?
                                this.state.userTypes.map(userType => {
                                  return <option value={userType.userType} >{userType.userType}</option>
                                }) : ''
                            }

                          </select>
                        </div>
                      </div>


                      <div className="row">
                        <div className="col-4">
                          <div className="form-group">
                            <label>Provider</label>
                            <input readOnly type="text" className="form-control copy-text" value={this.state.provider} onClick={(e) => this.handleCopyClick(e)} placeholder="Enter Provider" />
                            <span id="err">{this.state.provider_err}</span>
                          </div>
                        </div>

                        <div className="col-4">
                          <div className="form-group">
                            <label>IP</label>
                            <input readOnly type="text" className="form-control copy-text" value={this.state.ip} onClick={(e) => this.handleCopyClick(e)} placeholder="Enter IP" />
                            <span id="err">{this.state.ip_err}</span>
                          </div>
                        </div>

                        <div className="col-3">
                          <div className="form-group">
                            <label>Port</label>
                            <input readOnly type="text" className="form-control copy-text" value={this.state.port} onClick={(e) => this.handleCopyClick(e)} placeholder="Enter Port" />
                            <span id="err">{this.state.port_err}</span>
                          </div>
                        </div>
                        <div className="col-1">
                          <div className="refresh-button" onClick={this.getRandomProxy}><i className="fa fa-refresh"></i>
                          </div>
                        </div>


                      </div>

                      <div className="row">
                        <div className="col-4">
                          <div className="form-group">
                            <label>First Name</label>
                            <input readOnly type="text" className="form-control copy-text" value={this.state.firstName} onClick={(e) => this.handleCopyClick(e)} placeholder="Enter First Name" />
                            <span id="err">{this.state.firstName_err}</span>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label>Last Name</label>
                            <input readOnly type="text" className="form-control copy-text" value={this.state.lastName} onClick={(e) => this.handleCopyClick(e)} placeholder="Enter Last Name" />
                            <span id="err">{this.state.lastName_err}</span>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label>Birth Date</label>
                            <input readOnly type="text" className="form-control copy-text" value={this.state.birthdate} onClick={(e) => this.handleCopyClick(e)} placeholder="Enter Borth Date" />
                            <span id="err">{this.state.bday_err}</span>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-6">
                          <div className="form-group">
                            <label>User Name</label>
                            <input readOnly type="text" className="form-control copy-text" value={this.state.userName} onClick={(e) => this.handleCopyClick(e)} placeholder="Enter User Name" />
                            <span id="err">{this.state.userName_err}</span>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label>Password</label>
                            <input readOnly type="text" className="form-control copy-text" value={this.state.password} onClick={(e) => this.handleCopyClick(e)} placeholder="Enter Password" />
                            <span id="err">{this.state.passwd_err}</span>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-6">
                          <div className="form-group">
                            <label>E-Mail</label>
                            <div>
                              <input type="text" value={this.state.email} onChange={(e) => this.handleChangeEmail(e)} className="form-control" placeholder="Enter a valid e-mail" required />
                              <span id="err">{this.state.email_err}</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label>Phone Number</label>
                            <div>
                              <input type="text" value={this.state.phone} onChange={(e) => this.handleChangePhone(e)} className="form-control" placeholder="Enter Phone Number" />
                              <span id="err">{this.state.phone_err}</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-11">
                          <div className="form-group">
                            <label>Facebook Hobby Search</label>
                            <div>
                              <input readOnly type="text" value={this.state.hobby} className="form-control" placeholder="Enter a valid hobby" required />
                              <span id="err">{this.state.hobby_err}</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-1">
                          <div className="form-group">
                            <label></label>
                            <div className="col-xl-3 col-md-4 col-sm-6 copy-text" onClick={() => this.copyText('https://www.facebook.com/search/pages/?q="' + this.state.hobby + '"')}><i className="fa fa-copy"></i></div>
                            <div className="col-xl-3 col-md-4 col-sm-6 refresh-button copy-text" onClick={this.getHobby}><i className="fa fa-refresh"></i></div>
                          </div>
                        </div>
                      </div>

                      <div className="form-group m-b-0">
                        <div className="row">
                          <div className="col-3">
                            <button onClick={this.generateNewRecord} type="button" className="btn btn-primary waves-effect waves-light mt-5 mr-3">
                              Generate New
                            </button>

                          </div>

                          <div className="col-3">
                            <button onClick={() => this.activateProxy()} type="button" className="btn btn-primary waves-effect waves-light mt-5 mr-3">
                              Activate
                            </button>

                          </div>

                          <div className="col-3">
                            <button onClick={() => this.blockProxy()} type="button" className="btn btn-primary waves-effect waves-light mt-5 mr-3">
                              Block
                            </button>

                          </div>

                          <div className="form-group col-3">
                            <div>
                              <button onClick={this.setTextArea} type="button" className="btn btn-primary waves-effect mt-5 mr-3">
                                Copy
                              </button>
                            </div>
                          </div>

                        </div>
                      </div>

                    </form>
                  </div>
                </div>
              </div>

              <div className="col-lg-5">
                <div className="card m-b-20 text-area">
                  <div className="card-body">

                    <h4 className="mt-0 header-title">Text to copy</h4>

                    <form action="#">
                      <div className="form-group">
                        <div>
                          <textarea
                            ref={(textarea) => this.state.textToCopy = textarea}
                            value={this.state.textAreaText}
                            className="form-control"
                            rows={14} />
                          <span id="err">{this.state.min_err}</span>
                        </div>
                      </div>


                    </form>
                  </div>
                </div>

                <div className="card m-b-20">
                  <div className="card-body">

                    <div className="row">
                      <div className="col-sm-12">
                        <div className="card m-b-20">
                          <div className="card-body">

                            <h4 className="mt-4 mb-2 header-title">Links to social media</h4>

                            <div className="button-items">

                              <Button outline color="primary" onClick={(e) => {
                                this.copyText("http://facebook.com");
                              }}><i
                                className="fa fa-facebook-square"></i> Facebook
                              </Button>{' '}

                              <Button outline color="primary" onClick={(e) => {
                                this.copyText("https://www.instagram.com/");
                              }}><i
                                className="fa fa-instagram"></i> Instagram
                              </Button>{' '}

                              <Button outline color="primary" onClick={(e) => {
                                this.copyText("https://vk.com");
                              }}><i
                                className="fa fa-vk"></i> VK
                              </Button>{' '}

                              <Button outline color="primary" onClick={(e) => {
                                this.copyText("https://developer.twitter.com/en/apps");
                              }}><i
                                className="fa fa-twitter-square"></i> Twitter
                              </Button>{' '}

                              <Button outline color="primary" onClick={(e) => {
                                this.copyText("https://www.linkedin.com/signup/cold-join?trk=guest_homepage-basic_nav-header-join");
                              }}><i
                                className="fa fa-linkedin-square"></i> LinedIn
                              </Button>{' '}

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="row">
                      <div className="col-sm-12">
                        <div className="card m-b-20">
                          <div className="card-body">

                            <h4 className="mt-4 mb-2 header-title">Regular Mails</h4>

                            <div className="button-items">

                              <Button outline color="primary" onClick={(e) => {
                                this.copyText("https://login.yahoo.com/account/create?.src=fpctx&.intl=us&.lang=en-US&.done=https%3A%2F%2Fwww.yahoo.com&specId=yidReg");
                              }}><i
                                className="fa fa-yahoo"></i> Yahoo
                              </Button>{' '}


                              <Button outline color="primary" onClick={(e) => {
                                this.copyText("https://signup.live.com/signup?lcid=1033&wa=wsignin1.0&rpsnv=13&ct=1543157850&rver=7.0.6737.0&wp=MBI_SSL&wreply=https%3a%2f%2foutlook.live.com%2fowa%2f%3fnlp%3d1%26signup%3d1%26RpsCsrfState%3dcc16ae6b-c13a-858f-24ef-8b59cca001e2&id=292841&CBCXT=out&lw=1&fl=dob%2cflname%2cwld&cobrandid=90015&lic=1&uaid=fc5a18832d2d403796115d3772f08ad7");
                              }}><i
                                className="mdi mdi-microsoft"></i> Outlook
                              </Button>{' '}

                              <Button outline color="primary" onClick={(e) => {
                                this.copyText("https://accounts.google.com/signup/v2/webcreateaccount?service=mail&continue=https%3A%2F%2Fmail.google.com%2Fmail%2F%3Fpc%3Dtopnav-about-n-en&flowName=GlifWebSignIn&flowEntry=SignUp");
                              }}><i
                                className="mdi mdi-gmail"></i> Gmail
                              </Button>{' '}

                              <Button outline color="primary" onClick={(e) => {
                                this.copyText("https://login.aol.com/account/create?src=noSrc&specId=yidReg");
                              }}> AOL
                              </Button>{' '}

                              <Button outline color="primary" onClick={(e) => {
                                this.copyText("https://register.mailbox.org/en");
                              }}><i
                                className="mdi mdi-email"></i> MailBox
                              </Button>{' '}


                              <Button outline color="primary" onClick={(e) => {
                                this.copyText("https://www.zoho.com/mail/");
                              }}> Zoho
                              </Button>{' '}


                              <Button outline color="primary" onClick={(e) => {
                                this.copyText("https://www.fastmail.com/signup/");
                              }}> FastMail
                              </Button>{' '}


                              <Button outline color="primary" onClick={(e) => {
                                this.copyText("https://vfemail.net/register/");
                              }}> VfeMail
                              </Button>{' '}


                              <Button outline color="primary" onClick={(e) => {
                                this.copyText("https://mail.tutanota.com/");
                              }}> TutaNota
                              </Button>{' '}


                              <Button outline color="primary" onClick={(e) => {
                                this.copyText("https://account.protonmail.com/signup?language=en");
                              }}> ProtonMail
                              </Button>{' '}

                              <Button outline color="primary" onClick={(e) => {
                                this.copyText("https://www.mail2world.com/");
                              }}> mail2world
                              </Button>{' '}

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>





                    <div className="row">
                      <div className="col-sm-12">
                        <div className="card m-b-20">
                          <div className="card-body">

                            <h4 className="mt-4 mb-2 header-title">Fake Mail</h4>

                            <div className="button-items">

                              <Button outline color="primary" onClick={(e) => {
                                this.copyText("https://www.20minutemail.com/?en");
                              }}> 20minutemail
                              </Button>{' '}

                              <Button outline color="primary" onClick={(e) => {
                                this.copyText("https://tempail.com/en/");
                              }}> Tempail
                              </Button>{' '}

                              <Button outline color="primary" onClick={(e) => {
                                this.copyText("https://www.fakemail.net/");
                              }}> Tempmailaddress
                              </Button>{' '}

                              <Button outline color="primary" onClick={(e) => {
                                this.copyText("https://www.temporary-mail.net/");
                              }}> Fakemailgenerator
                              </Button>{' '}

                              <Button outline color="primary" onClick={(e) => {
                                this.copyText("https://tempm.com/");
                              }}> Tempm
                              </Button>{' '}


                              <Button outline color="primary" onClick={(e) => {
                                this.copyText("https://10minutemail.net/");
                              }}> 10minutemail
                              </Button>{' '}


                              <Button outline color="primary" onClick={(e) => {
                                this.copyText("https://temp-mail.org/en/");
                              }}> Temp-mail
                              </Button>{' '}


                              <Button outline color="primary" onClick={(e) => {
                                this.copyText("https://emailfake.com/");
                              }}> Emailfake
                              </Button>{' '}

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-2">
                <div className="card m-b-10 card-body text-white card-primary  slidecontainer">
                  <blockquote className="card-blockquote mb-0 slider">
                    <h5>Your activations today: {this.state.employee.totalActivations}</h5>

                    <table className="table mb-0">
                      <thead>
                        <tr>
                          <th>Hour</th>
                          <th scope="row">Total Per Hour</th>
                        </tr>
                      </thead>


                      <tbody>
                        {this.state.employee.dataPerHourForTable ?
                          this.state.employee.dataPerHourForTable.map(hourlyData => {
                            return (
                              hourlyData.hourInDay > 0 ?
                                <tr>
                                  <td>{hourlyData.hourInDay + ":00 - " + hourlyData.hourInDay + ":59"}</td>
                                  <td>{hourlyData.countPerHour}</td>
                                </tr> : <></>)
                          }) : <></>}

                        <tr>
                          <td>Total Block</td>
                          <td>{this.state.employee.totalDailyBlock}</td>
                        </tr>

                      </tbody>
                    </table>

                  </blockquote>
                </div>

              </div>

            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default Form_user;