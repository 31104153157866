import React, { Component } from 'react'
import ReactHTMLTableToExcel_XLSX from 'react-html-table-to-excel';
import * as Api from '../../../Api/Axios';
import AUX from '../../../hoc/Aux_';


export class ExportExcel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ProxyData: [],
            currentDate: '',
        }

    }

    activityToGet = 'Activate';


    componentDidMount() {
    }

    onSetDate = (e) => {
        let dateValue = e.target.value;
        let current = dateValue === '' ? new Date() : new Date(dateValue);
        let year = current.getFullYear();
        let month = (current.getMonth() + 1) < 9 ? "0" + (current.getMonth() + 1) : (current.getMonth() + 1);
        let day = current.getDate() < 9 ? "0" + current.getDate() : current.getDate();
        let strDate = year + "-" + month + "-" + day;
        console.log("current.getDate():", current.getDate());
        console.log("date: ", strDate);
        this.setState({ currentDate: strDate }, () => {
            this.getDateForTable();
            this.filename = "Good_users_report." + this.state.currentDate;
        });

    }

    getDateForTable = async () => {
        let res = await Api.postRequest("getGoodUsersData.php", { activityToGet: this.activityToGet, reportDate: this.state.currentDate });
        let reportDetails = [];
        console.log("result of getGoodUsersData", res);
        if (res.data.status == 201) {
            reportDetails = [...res.data.response.data];

            console.log("reportDetails: ", reportDetails);
            if (reportDetails.length > 0) {
                this.setState({ ProxyData: reportDetails });
            }
        } else {
            this.setState({ ProxyData: [] });
        }
    }

    onChange = (e) => {
        this.activityToGet = e.target.value;
        this.getDateForTable();
    }


    render() {
        return (

            <AUX>
                <div className="wrapper">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-sm-12">
                                <div className="page-title-box">
                                    <div className="btn-group pull-right">
                                        <ol className="breadcrumb hide-phone p-0 m-0">
                                            {/* <li className="breadcrumb-item active">Dashboard 1</li> */}
                                        </ol>
                                    </div>
                                    <h4 className="page-title">Daily Report</h4>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-4"></div>
                            <div className="col-4">
                                <div className="form-group row">
                                    <label for="activityToGet" className="col-sm-2 col-form-label">Activity to get</label>
                                    <div className="col-sm-10">
                                        <select className="form-control" id="activityToGet" placeholder="Enter Activity" onChange={(e) => this.onChange(e)}>
                                            <option value={"Activate"} >Activated Proxies</option>
                                            <option value={"Block"} >Blocked Proxies</option>
                                            <option value={"ALL"} >All</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4"></div>
                        </div>

                        <div className="row">
                            <div className="col-4"></div>
                            <div className="col-4">
                                <div className="form-group row">
                                    <label for="example-date-input" className="col-sm-2 col-form-label">Date</label>
                                    <div className="col-sm-10">
                                        <input className="form-control" type="date" value={this.state.currentDate} id="example-date-input" onChange={(e) => this.onSetDate(e)} />
                                    </div>
                                </div>

                            </div>
                            <div className="col-4"></div>
                        </div>

                        <div>
                            <div className="row mt-5">
                                <table id="table-to-xls" class="table">
                                    <thead>
                                        <tr>
                                            <th>Email</th>
                                            <th>Password</th>
                                            <th>IP</th>
                                            <th>Port</th>
                                            <th>Date</th>
                                            <th>User Type</th>
                                            <th>Provider</th>
                                            <th>Time</th>
                                            <th>Activity</th>
                                            <th>F.Name</th>
                                            <th>L.Name</th>
                                            <th>Emp Id</th>
                                            <th>User Name</th>
                                            <th>Full Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>              {
                                        this.state.ProxyData.map((p, index) => {
                                            return <tr key={index}>
                                                <td >{p.email}</td>
                                                <td >{p.password}</td>
                                                <td >{p.IP}</td>
                                                <td >{p.port}</td>
                                                <td style={{ paddingRight: "114px" }} >{p.reportDate}</td>
                                                <td >{p.userType}</td>
                                                <td >{p.provider}</td>
                                                <td >{p.logTime}</td>
                                                <td >{p.activity}</td>
                                                <td >{p.userFirstName}</td>
                                                <td >{p.userLastName}</td>
                                                <td >{p.employeeId}</td>
                                                <td >{p.userName}</td>
                                                <td >{p.fullName}</td>
                                            </tr>
                                        })
                                    }

                                    </tbody>

                                </table>

                            </div>
                            <div>
                                <ReactHTMLTableToExcel_XLSX
                                    id="test-table-xls-button"
                                    className="download-table-xls-button"
                                    table="table-to-xls"
                                    filename={this.filename}
                                    sheet="Good Users"
                                    buttonText="Export excel" />
                            </div>
                        </div>
                    </div>
                </div>
            </AUX>
        )
    }
}

export default ExportExcel