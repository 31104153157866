import axios from 'axios';
let GlobalURL = 'http://babvo.com/API/';

export function getRequest(url) {
    const headers = {
        'Access-Control-Allow-Origin':'*'
    }
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios.get(GlobalURL + url, headers)
            resolve(res);
        } catch (err) {
            resolve("Error : ", err);
        }
    });
}


export function postRequest(url, ob) {
    const headers = {
        'Content-Type': 'json',
        'Access-Control-Allow-Origin':'*'
    }
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios.post(GlobalURL + url, ob,headers )
            resolve(res);
        } catch (err) {
            resolve("Error : ", err);
        }
    });
}
