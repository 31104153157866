
      export function isLoggedIn(){
        return getRole() !=='0';
      }

      export function logOut(){
        sessionStorage.setItem("userInfo", '');
        window.location.replace("/");
      }

      export function getToken(){

      }

      export function getRole(){
        if (sessionStorage.getItem("userInfo") && sessionStorage.getItem("userInfo").length > 0) {
          if (JSON.parse(sessionStorage.getItem("userInfo"))[0].userRole) {
            return JSON.parse(sessionStorage.getItem("userInfo"))[0].userRole;
          } else {
            return '0';
          }
        } else {
          return '0';
        }
      }

      export function isAdmin(){
        console.log("getRole() : ", getRole());
        console.log("getRole() === '3'", getRole() === '3');
        return getRole() === '3';
      }

      export function isMaster(){
        console.log("getRole() : ", getRole());
        console.log("getRole() === '2'", getRole() === '2');
        return getRole() === '2';
      }

      export function isUser(){
        console.log("getRole() : ", getRole());
        console.log("getRole() === '1'", getRole() === '1');
        return getRole() === '1';
      }

      export function getUserName(){
        return JSON.parse(sessionStorage.getItem("userInfo")).username;
      }

      export function getEmployeeId(){
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        let employeeId;
        if (Array.isArray(JSON.parse(sessionStorage.getItem("userInfo")))) {
          employeeId = JSON.parse(sessionStorage.getItem("userInfo"))[0].employeeId;
          console.log("userInfo", userInfo, employeeId);
          return JSON.parse(sessionStorage.getItem("userInfo"))[0].employeeId;
        }
        return -1;
      }


 