import React, {Component} from "react";
import ExportExcel from '../Components/component_exportExcel'

class DailyReport extends Component {
    state = {
    };

  render() {
    return (
      <div>
          <ExportExcel/>
      </div>
    );
  }
}

export default DailyReport;