import React, { Component } from "react";
import UserStatusUpdater from '../Components/component_userStatusUpdater';

class Pages_ResumeLockedUser extends Component {
    state = {
    };

    userStatus = 'C';
  


  render() {
    return (
      <div>
          <UserStatusUpdater userStatus={this.userStatus}>
          </UserStatusUpdater>
      </div>
    );
  }
}

export default Pages_ResumeLockedUser;