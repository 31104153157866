import React, { Component } from "react";
import ReleaseProxies from '../Components/component_releaseProxies';

class Pages_ReleaseProxies extends Component {
  state = {
  };

  render() {
    return (
      <div>
        <ReleaseProxies/>
      </div>
    );
  }
}

export default Pages_ReleaseProxies;