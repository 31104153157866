import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import ReactPlayer from 'react-player'




class Home extends Component {

    constructor() {
        super();
        this.state = {
            key:0
        }
    }

    componentDidMount() {
        console.log("YYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYY");
        this.setState({key:1});
    }

    

    render() {
        return (
            <AUX>

                <div className="wrapper">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-sm-12">
                                <div className="page-title-box">
                                    <div className='player-wrapper'>
                                        <ReactPlayer key = {this.state.key}
                                            url='http://babvo.com/assets/Kinetic Frames Opener_720p.mp4'
                                            width='70%'
                                            height='70%'
                                            playing={true}
                                            style={{margin: "auto"}}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </AUX>
        );
    }
}

export default Home;