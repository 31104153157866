import React, {Component} from "react";
import readXlsxFile from 'read-excel-file';
import AUX from '../../../hoc/Aux_';
import { Link } from 'react-router-dom';
import * as Api from '../../../Api/Axios';
import { Progress } from 'reactstrap';

class ExcelLoader extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            importedList:[], 
            fileInputDescription : "",
            loadButtonTitle : "",
            pageTitle: ""
        }
        
    }

    excelType = this.props.excelFileType;

    componentDidMount(){
        switch (this.excelType){
            case "proxyList":
                this.setState({fileInputDescription:"Press to load proxy list files"});
                this.setState({loadButtonTitle:"Load Proxies"});
                this.setState({pageTitle:"Load New Proxies"});
                this.setState({sectionTitle:"Proxies"});
                break;
            case "firstNameList":
                this.setState({fileInputDescription:"Press to load first names list files"});
                this.setState({loadButtonTitle:"Load First Names"});
                this.setState({pageTitle:"Load New First Names"});
                this.setState({sectionTitle:"First Names"});
                break;
            case "lastNameList":
                this.setState({fileInputDescription:"Press to load last names list files"});
                this.setState({loadButtonTitle:"Load Last Names"});
                this.setState({pageTitle:"Load New Last Names"});
                this.setState({sectionTitle:"Last Names"});
                break;
            case "hobbies":
                this.setState({fileInputDescription:"Press to load hobbies list files"});
                this.setState({loadButtonTitle:"Load hobbies"});
                this.setState({pageTitle:"Load hobbies"});
                this.setState({sectionTitle:"Emails"});
                break;
            case "userType":
                this.setState({fileInputDescription:"Press to load user type list files"});
                this.setState({loadButtonTitle:"Load user types"});
                this.setState({pageTitle:"Load user types"});
                this.setState({sectionTitle:"User Types"});
                break;
        }
    }


    previewFileContent(selectedFile) {
        console.log("in Read file");
        let f = selectedFile;
        this.setState({file:selectedFile});
        console.log("State:", this.state);
        console.log("State file", this.state.file);
        console.log("f:", f);
        
        if (f){
            let name = f.name;
            readXlsxFile(f).then((rows) => { 
                // `rows` is an array of rows    // each row being an array of cells. 
                this.convertToJson(rows) ;
            })
        }
    }

    filePathset(e) {
        console.log("select file started");
        e.stopPropagation();
        e.preventDefault();
        let importedList=[];
        let selectedFile = e.target.files[0];
        console.log("File------------->",selectedFile);
        
        this.setState({importedList});

        this.previewFileContent(selectedFile);
        
    }
   

    uploadFile = async ()=>{
        let url = `loadExcel.php`;

        console.log("url=", url)

        let pageSize = Math.ceil(this.state.importedList.length/100);
        console.log("pageSize:", pageSize);
        if(pageSize<1)pageSize=1;
        let start = 0;
        let end = pageSize;
        let partialArray =[];
        let progress = Math.floor(100/(this.state.importedList.length/pageSize));
        let progreassMeasure = 0;
        this.setState({progreassMeasure});

        let dropArchieveRes, archiveRes, truncateRes;


        switch (this.excelType){
            case "proxyList": 
                dropArchieveRes = await Api.postRequest("dropArchieveTable.php", {});
                archiveRes = await Api.postRequest("duplicateProxyToArchieve.php",{});
                truncateRes = await Api.postRequest("truncateTable.php",{tableName:"proxies"});
                break;
            case "firstNameList":
                truncateRes = await Api.postRequest("truncateTable.php",{tableName:"firstNames"});
                break;
            case "lastNameList":
                truncateRes = await Api.postRequest("truncateTable.php",{tableName:"lastNames"});
                break;
            case "hobbies":
                truncateRes = await Api.postRequest("truncateTable.php",{tableName:"hobbies"});
                break;
            case "userType":
                truncateRes = await Api.postRequest("truncateTable.php",{tableName:"userType"});
                break;
        }

        while((partialArray = this.state.importedList.slice(start,end)).length > 0){
            start += pageSize;
            end += pageSize;
            console.log("start:", start);
            console.log("partialArray:", partialArray);
            console.log("importedList:", this.state.importedList);
            let res = await Api.postRequest(url, {importedList: partialArray, mode: this.excelType});
            console.log("res=",res);
            progreassMeasure += progress;
            this.setState({progreassMeasure});
        }
        this.setState({progreassMeasure:100});

        if (this.excelType == "proxyList"){
            let postActivitiesRes = await Api.postRequest("proxyPostLoadActivities.php", {});
        }
    }


  convertToJson(excelRows) {
    var lines = excelRows;

    var result = [];

    var headers = [...lines[0]];

    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      var currentline = [...lines[i]];

      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }

      result.push(obj);

    }

    //return result; //JavaScript object
    let data = [...result];
    console.log("Excel Content:", data);
    this.setState({importedList:data});
  }

  render() {

    return (
      <div>
          <AUX>
		   
           <div className="wrapper">
           <div className="container-fluid">

               <div className="row">
                   <div className="col-sm-12">
                       <div className="page-title-box">
                           <div className="btn-group pull-right">
                               <ol className="breadcrumb hide-phone p-0 m-0">
                                   <li className="breadcrumb-item"><Link to="#">Pages</Link></li>
                                   <li className="breadcrumb-item active">{this.state.sectionTitle}</li>
                               </ol>
                           </div>
                           <h4 className="page-title">{this.state.pageTitle}</h4>
                       </div>
                   </div>
               </div>

               <div className="row">
                   <div className="col-12">
                       <div className="card">
                           <div className="card-body">
                               <div className="row m-t-40">
                                   <div className="col-md-4">
                                   </div>
                                   <div className="col-md-8">
                                       <form className="form-custom">
                                           <div className="row">
                                               <div className="col-md-6">
                                                   {/* <div className="form-group">
                                                       <label className="sr-only" for="username">Name</label>
                                                       <input type="file" 
                                                        className="form-control" 
                                                        id="proxy-file"
                                                        ref="fileUploader"
                                                        onChange={this.filePathset.bind(this)} required="" />
                                                   </div> */}

                                                   <div className="input-group form-group">
                                                        <div className="custom-file form-group">
                                                            <input
                                                            type="file"
                                                            className="custom-file-input"
                                                            id="input-file"
                                                            ref="fileUploader"
                                                            // onChange={this.filePathset.bind(this)} required=""
                                                            onChange={(e)=>this.filePathset(e)} 
                                                            required=""
                                                            aria-describedby="inputGroupFileAddon01" 
                                                            placeholder="Load File"/>
                                                            <label className="custom-file-label" htmlFor="inputGroupFile01">
                                                                {this.state.fileInputDescription}
                                                            </label>
                                                        </div>
                                                    </div>

                                               </div>
                                            </div>
                                           <div className="row">
                                
                                               <div className="col-md-6 text-center">

                                               {/* <button type="button" className="btn btn-primary waves-effect waves-light mr-2"
                                                    onClick={() => {
                                                        this.readFile();
                                                      }}>Preview Data</button> */}

                                                   <button type="button" className="btn btn-primary waves-effect waves-light"
                                                    onClick={() => {
                                                        this.uploadFile();
                                                      }}>{this.state.loadButtonTitle}</button>
                                               </div>


                                               

                                           </div>
                                           <div className="row mt-5">
                                                <div className="col-lg-6">
                                                    <div className="card m-b-20">
                                                        <div className="card-body">

                                                            <h4 className="mt-0 header-title">Loading Progress</h4>
                                                            <div className="">
                                                                <Progress value={this.state.progreassMeasure}>{this.state.progreassMeasure}%</Progress>     
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                           </div>
                                       </form>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
           </div> 
       </div>

       <div className="wrapper">
            <div className="container-fluid">

                <div className="row">
                    <div className="col-sm-12">
                        <div className="page-title-box">
                            <div className="btn-group pull-right">
                                <ol className="breadcrumb hide-phone p-0 m-0">
                                    <li className="breadcrumb-item active">Input List</li>
                                </ol>
                            </div>
                            <h4 className="page-title">Input List</h4>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-12">
                        <div className="card m-b-20">
                            <div className="card-body">

                                <div className="table-rep-plugin">
                                    <div className="table-responsive mb-0" data-pattern="priority-columns">
                                        <table id="tech-companies-1" className="table  table-striped">
                                            <thead>
                                                
                                                    {
                                                    this.props.excelFileType == "proxyList"?
                                                        <tr>
                                                            <th>Provider</th>
                                                            <th data-priority="1">IP Address</th>
                                                            <th data-priority="3">Port</th>
                                                        </tr>
                                                    :this.props.excelFileType == "hobbies"?
                                                    <tr>
                                                        <th>Hobby</th>
                                                    </tr>:this.props.excelFileType == "userType"?
                                                    <tr>
                                                        <th>User Type</th>
                                                    </tr>:
                                                    <tr>
                                                        <th>Name</th>
                                                    </tr>
                                                    }
                                                
                                            </thead>
                                            <tbody>
                                            {
                                                this.props.excelFileType == "proxyList"?
                                                this.state.importedList.map(row => {
                                                    return <tr>
                                                        <td>{row.Provider}</td>
                                                        <td>{row.IP}</td>
                                                        <td>{row.Port}</td>
                                                    </tr>
                                                }):this.props.excelFileType === "hobbies"?
                                                this.state.importedList.map(row => {
                                                    return <tr>
                                                        <td>{row.hobby}</td>
                                                    </tr>
                                                }):this.props.excelFileType === "userType"?
                                                this.state.importedList.map(row => {
                                                    return <tr>
                                                        <td>{row.userType}</td>
                                                    </tr>
                                                }):
                                                this.state.importedList.map(row => {
                                                    return <tr>
                                                        <td>{row.Name}</td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>


          </AUX>
      </div>
    );
  }
}

export default ExcelLoader;